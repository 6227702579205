import React, { useState, useEffect } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import {dataSet} from "./dataSet"
import ListTable from "./ListTable/ListTable";
// import TableComponent from "./TableComponent/TableComponent";
import { toastr } from 'react-redux-toastr';
import Dropdown from '../form-controls/Dropdown';
import Input from '../form-controls/Input';
import api from "../../api";
import { RouteKeys } from "../../containers/routes/route-keys";

function AddDataset() {
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [textInput, setTextInput] = useState("");
  const [dsValue, setDsValue] = useState(null);
  const [sourceDataList, setSourceDataList] = useState([]);
  const [tablesList, setTablesList] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [error, setError] = useState({
    name: "",
    datasource: "",
    tables: ""
  });
  const navigate = useNavigate()

  useEffect(() => {
    const fetchSourceList = async () => {
      try {
        const sourceList = await api.sourceList.getSourceList({
          resource: 'api/vault',
        });
        setSourceDataList(sourceList.data?.data?.keys.map(src => {
          return {
            connectorName: src.connectorName,
            type: ["azureblob", "s3"].includes(src.connector) ? "csv" : "table"
          }
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchSourceList();
  }, []);

  const fetchTableList = async (dsValue) => {
    try {
      setIsTableLoading(true);
      const reqData = {
        "Datasource_Name": dsValue,
        "type": "noColumns"
      }
      const tableData = await api.dataset.getDatasourceTables({
        resource: 'api/datasets/tables',
      }, { ...reqData });

      if (tableData?.data?.status !== 200) {
        setIsTableLoading(false);
        console.error(tableData?.data.message);
        return toastr.error("Error", "Tables not found");

      }
      setTablesList(tableData?.data?.data);
      setIsTableLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const saveDataset = async () => {
    try {
      setIsLoading(true);
      setError({
        name: "",
        datasource: "",
        tables: ""
      })
      const reqData = {
        "DatasetName": textInput,
        "Datasource_Name": dsValue,
        "data": checked,
        // "type": "table"
        "type": sourceDataList?.filter(src=>src.connectorName===dsValue)[0]?.type
      }
      const saveDataResponse = await api.dataset.addDataset({
        resource: 'api/datasets/create',
      }, { ...reqData });
      // console.info(saveDataResponse)
      if (saveDataResponse.status !== 200) {
        setIsLoading(false)
        return toastr.error("Error", saveDataResponse?.data.message);
      } else {
        setIsLoading(false)
        navigate(RouteKeys.Dataset);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div>
        <h5 className="text-dark text-center pt-4 fw-bold">Add Dataset</h5>
      </div>
      <div className="mx-5 my-3">
        {isLoading && (
          <Spinner
            className="position-absolute opacity-100 top-50 start-50"
            style={{ zIndex: 1 }}
          ></Spinner>
        )}
        <Input
          // {...field}
          label="Dataset Name"
          type="text"
          isMandatory={true}
          disabled={isLoading}
          value={textInput}
          error={error.name}
          style={{
            border: 'none',
            borderBottom:
              '1px solid lightgrey',
            borderRadius: 'unset',
          }}
          className="line-input"
          onChange={(e) => {
            if(e.target.value) {
              setError({...error, name: ""});
            } else{
              setError({...error, name: "Dataset name is required"});
            }
            setTextInput(e.target.value)
          }}
        />
        <Dropdown
          id="table_name"
          label="Data Source"
          isMandatory={true}
          disabled={isLoading}
          error={error.datasource}
          busy={
            sourceDataList?.length > 0
              ? false
              : true
          }
          value={dsValue}
          data={sourceDataList?.map(src => src.connectorName)}
          onChange={(value) => {
            setError({...error, datasource: ""});
            if (dsValue != value) {
              setDsValue(value)
              fetchTableList(value);
              setTablesList([]);
            }
          }}
        />
        <Row className="mt-1">
          <Col>
            <ListTable
              checked={checked}
              setChecked={setChecked}
              tablesList={tablesList}
              isLoading={isLoading}
              isTableLoading={isTableLoading}
              error={error}
              setError={setError}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            type="button"
            disabled={isLoading}
            onClick={() => navigate(RouteKeys.Dataset)}
            className="btn btn my-3  mt-4 mx-3 "
            variant="outline-secondary"
            size="md"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            type="submit"
            variant="success"
            className="btn btn my-3   mt-4 px-3"
            style={{
              backgroundColor: 'rgb(73, 151, 73)',
            }}
            onClick={() => {
              // console.info({ checked, textInput, dsValue })
              if (textInput && dsValue && checked?.length) {
                saveDataset();
              } else {
                let errorObject = { name: "", datasource: "", tables: "" }
                if (!textInput) {
                  errorObject = {
                    ...errorObject, name: "Dataset name is required"
                  }
                }
                if (!dsValue) {
                  errorObject = {
                    ...errorObject, datasource: "Please select data source"
                  }
                }
                if (!checked?.length) {
                  errorObject = {
                    ...errorObject, tables: "Please select atleast one table"
                  }
                }
                setError(errorObject)
              }
            }}
          >
            <>{'Save'}</>
          </Button>
        </div>
      </div>
    </>
  );
}

export default AddDataset;

