import React, { useMemo } from 'react';
import { useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import { useTable } from 'react-table';
// import ModalPage from '../../../modal/modal';
import './nestedTable.scss';
import QueryUserModel from '../../../modal/queryUserModel';
import api from '../../../../api';
import { useSelector } from 'react-redux';
import UserQueryModal from '../../../modal/userQueryModal';

export default function NestedTable({ nestedColumns, data, queryId, userId, fetchQueries }) {
    const authState = useSelector((state) => state.authUser);
    const memoziedFilteredColumns = useMemo(() => nestedColumns, []);
    const memeoziedRow = useMemo(() => data, [data]);
    const tableInstance = useTable({
        columns: memoziedFilteredColumns,
        data: memeoziedRow,
    });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;
    const [show, setShow] = useState(false);

    return (

        <div className="d-flex align-items-start ">
            
            {(show && window.location.pathname === "/serviceuser") ? 
            <UserQueryModal
             show={show} 
             onHide={() => setShow(false)}
             heading={'Manage Sharing'}
             close={"Save"}
             fetchQueries={fetchQueries}
             Selected={memeoziedRow.map(({queryName, QueryID}) => {return {queryName, QueryID}})}
             userId={userId}
            /> : <QueryUserModel
                show={show}
                onHide={() => setShow(false)}
                heading={'Manage Sharing'}
                close={"Save"}
                fetchQueries={fetchQueries}
                Selected={memeoziedRow.map(({ first_name, last_name, id }) => { return { first_name, last_name, id } })}
                queryId={queryId}
            />
            
            }
            <div className='p-1 scroll-div '>
                <Table {...getTableProps()} responsive size="sm" className='nestedTable'>
                    <thead className="text-dark">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        className="fw-light text-secondary"
                                        {...column.getHeaderProps()}
                                    >
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody
                        {...getTableBodyProps()}
                        className="text-dark fw-bolder"
                    >
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
            {
            // authState.profile.role_id === 1 && 
            <Button variant="success" className='mt-2'
                onClick={() => {
                   
                    setShow(true)
                }}
            >Manage Sharing
            </Button>
            }
        </div>
    );
}
