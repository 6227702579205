import React,{useState} from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useNavigate,useParams } from "react-router-dom";
import api from "../../../api";

const fixedBottomStyle = {
  position: "fixed",
  left: 0,
  bottom: 0,
  width: "100%",
  padding: "10px",
};

const QueryBuilderFooter = ({screen}) => {
  const dispatch = useDispatch();
  const navigate= useNavigate();
  const params = useParams();

  const [disableButton, setDisableButton] = useState(false);


  const {
    tableData,
    datasetResponse,
    sortKeyvalue,
    groupByValues,
    ddValues,
    queryName,
    isLoadingPreview,
    filterValues,
    sortValues
  } = useSelector((state) => state.queryBuilder);



  const generateParams = () => {
    const QueryStore = {};
    Object.keys(tableData).map((key) => {
      const obj = datasetResponse.find((d) => d.id === key);


      const datasourceName = obj ? obj.Datasource_Name : null;

      if (!QueryStore[datasourceName]) {
        QueryStore[datasourceName] = {};
      }
      if (!QueryStore[datasourceName]["DatasetEntity"]) {
        QueryStore[datasourceName]["DatasetEntity"] = [];
      }
      if (!QueryStore[datasourceName]["Entities"]) {
        QueryStore[datasourceName]["Entities"] = [];
      }

      if (!QueryStore[datasourceName]["group"]) {
        QueryStore[datasourceName]["group"] = [];
      }
      if (!QueryStore[datasourceName]["entites"]) {
        QueryStore[datasourceName]["entites"] = [];
      }

      if (!QueryStore[datasourceName]["limit"]) {
        QueryStore[datasourceName]["limit"] = 10;
      }

      let datasetEntities = [];
      const entity = [];
      Object.keys(tableData[key])?.map((tableName) => {
        const entityArr = [key, tableName];
        datasetEntities.push(entityArr);
        entity.push(tableName);
      });

      const entityValue = [];

      ddValues.forEach((val) => {
        if (val.value.split(".")[0] === key) {
          const tableIndex = entityValue.findIndex(
            (item) => item.table === val.label.split(".")[0]
          );
          if (tableIndex === -1) {
            entityValue.push({
              table: val.label.split(".")[0],
              columns: [val.label],
            });
          } else {
            entityValue[tableIndex].columns.push(val.label);
          }
        }
      });

    //   const filterVal=[]
    //   if(filterValues.length!==0){
    //   filterValues?.map((fv, index)=>{

    //     if(fv.colName!=""){
    //     const obj={}
    //     obj["col"]= fv.colName.label
    //     obj["op"]= fv.Operator.value || ''
    //     obj["val"]= fv.Value
    //     obj["sequence"]= index
    //     obj["condition"]= fv.condition
    //     filterVal.push(obj);
    //     }
    //   })
    // }

      QueryStore[datasourceName]["DatasetEntity"].push(...datasetEntities);
      QueryStore[datasourceName]["Entities"].push(...entity);


      // if (sortKeyvalue[key]) {
      //   QueryStore[datasourceName]["sort"].push(...sortKeyvalue[key]);
      // }
      // QueryStore[datasourceName]["group"] = groupByValues;
      QueryStore[datasourceName]["entites"].push(...entityValue);
      // QueryStore[datasourceName]["filter"].push(...filterVal);
    });

    return QueryStore;
  };

  const handleSave = () => {

    try{
    if (queryName === "") {
      return toastr.error("Please enter query name");
    }
    if (queryName.length <5) {
      return toastr.error("Query must be atleast 5 characters");
    }
    if (Object.keys(tableData).length === 0) {
      return toastr.error("Please select atleast one table");
    }
    setDisableButton(true);
    const QueryStore = generateParams();  

    const finalQuery={};
     finalQuery["queryname"]= queryName;
     finalQuery["querystore"]= QueryStore;

     const filterVal=[]
     if(filterValues.length!==0){
     filterValues?.map((fv, index)=>{
 
      let dataSourceName='';

      
      
      if(fv.colName!=""){

        Object.keys(QueryStore).map((ds)=>{
          if(QueryStore[ds]["Entities"].includes(fv.colName.label.split(".")[0])){
            dataSourceName=ds;
          }
        })
      const obj={}
      const columnName= dataSourceName.concat(".", fv.colName.label);
      obj["col"]= columnName
       obj["op"]= fv.Operator.value || ''
       obj["val"]= fv.Value
       obj["sequence"]= index
       obj["condition"]= fv.condition
       filterVal.push(obj);
       }
     })
   }

   finalQuery["sort"]= sortValues;
   finalQuery["filter"]= filterVal;
   


    const queryBuilderParams = {
      query: finalQuery,
    };

    if(screen === "addQuery"){
    const request = {
      resource: "api/queries/create",
    };

    const TableResponse = api.dataset.getDatasourceTables(
      request,
      queryBuilderParams
    );
    
    TableResponse.then((res) => {
    
      setDisableButton(false);

      if(res.data.status==200){
        dispatch({ type: "ErrorQuery", payload: {} });
        dispatch({ type: "ResetState"});
        navigate("/query");
      return toastr.success(res.data.message);
      }
      else{
        if(typeof(res.data.message) === "string"){
          dispatch({ type: "ErrorQuery", payload: {} });

          return toastr.error(res.data.message);
        } else{

          
          dispatch({ type: "ErrorQuery", payload: res.data.message });

          return toastr.error("Error in query");
        }
      }
    }).catch((err)=>{
      setDisableButton(false);
      return toastr.error("Error in query");

    });
  }else if(screen === 'editQuery'){

    const request = {
      resource: "api/queries/update",
      id:params.id
    };

    const TableResponse = api.dataset.updateDataset(
      request,
      queryBuilderParams
    );
    
    TableResponse.then((res) => {
      if(res.data.status==200){
        dispatch({ type: "ErrorQuery", payload: {} });
        dispatch({ type: "ResetState"});
        navigate("/query");
      return toastr.success(res.data.message);
      }
      else{
        if(typeof(res.data.message) === "string"){
          dispatch({ type: "ErrorQuery", payload: {} });

          return toastr.error(res.data.message);
        } else{

          
          dispatch({ type: "ErrorQuery", payload: res.data.message });

          return toastr.error("Error in query");
        }
      }
    }).catch((err)=>{
      
      return toastr.error("Error in query");

    });

  }
} catch(err){
  setDisableButton(false);
  console.error(err);
}
  };

  const handlePreview = () => {

    // console.info({

    //   tableData,
    //   datasetResponse,
    //   sortKeyvalue,
    //   groupByValues,
    //   ddValues,
    //   queryName,
    //   isLoadingPreview,
    //   filterValues,
    //   sortValues
    // });

    if (Object.keys(tableData).length === 0) {
      return toastr.error("Please select atleast one table");
    }
try{
    dispatch({ type: "PreviewLoading", payload: true });
    setDisableButton(true);


    const QueryStore = generateParams();

    const filterVal=[]

    if(filterValues.length!==0){
    filterValues?.map((fv, index)=>{
      
      let dataSourceName='';
      if(fv.colName!=""){

      Object.keys(QueryStore).map((ds)=>{
        if(QueryStore[ds]["Entities"].includes(fv.colName.label.split(".")[0])){
          dataSourceName=ds;
        }
      })


      const obj={}
      const columnName= dataSourceName.concat(".", fv.colName.label);
      obj["col"]= columnName
      obj["op"]= fv.Operator.value || ''
      obj["val"]= fv.Value
      obj["sequence"]= index
      obj["condition"]= fv.condition
      filterVal.push(obj);
      }
    })
  }


    if (QueryStore) {
      const request = {
        resource: "api/queries/preview",
      };
      // QueryStore["filter"] = filterVal;
      // QueryStore["sort"] = sortValues;

      const queryParam = {}
      const modifiedSortValues = sortValues.map(val => {
        const [dsId, tab_name, col_name] = val.value.split(".")
        const findDataSource = datasetResponse?.filter(ds => ds.id === dsId)[0]?.Datasource_Name;
        return {
          order: val.label.split(".")[2],
          col: `${findDataSource}.${tab_name}.${col_name}`
        }
      })
      // console.info({modifiedSortValues});
      queryParam["querystore"]= QueryStore
      queryParam["filter"]= filterVal
      // queryParam["sort"]= sortValues
      queryParam["sort"]= modifiedSortValues


      const queryPreviewParams = {
        queryName: queryName,
        query: queryParam,
      };

      const previewResponse = api.dataset.getDatasourceTables(
        request,
        queryPreviewParams
      );

      
      previewResponse.then((res) => {
        dispatch({ type: "PreviewLoading", payload: false });
        // dispatch({ type: "IsEditing", payload: false });
        setDisableButton(false);


        if (res.data.status === 200) {
          dispatch({ type: "ErrorQuery", payload: {} });
          dispatch({ type: "PreviewData", payload: res.data.data });
          dispatch({ type: "ActiveTab", payload:"Preview" });


          return toastr.success(res.data.message);
        } 
        else{
          dispatch({ type: "PreviewLoading", payload: false });
          if(typeof(res.data.message) === "string"){
            dispatch({ type: "ErrorQuery", payload: {} });
  
            return toastr.error(res.data.message);
          } else{
            
            dispatch({type:'ActiveTab',payload: 'ErrorQuery'}) 
            dispatch({ type: "ErrorQuery", payload: res.data.message });
  
            return toastr.error("Error in query");
          }
        }

      })

    }
  } catch(err){
    console.error(err);
    setDisableButton(false);
    dispatch({ type: "PreviewLoading", payload: false });
    return toastr.error("Error while getting preview")

  }
  };


  return (
    <div className="" style={fixedBottomStyle}>
      <div class=" d-flex flex-row-reverse">
        <button type="button" class="btn btn-danger m-2" onClick={handleSave} disabled={isLoadingPreview || disableButton}>
          Save Query
        </button>
        <button
          type="button"
          class="btn btn-primary m-2"
          disabled={isLoadingPreview || disableButton}
          onClick={handlePreview}
        >
          Preview
        </button>
        <button type="button" class="btn btn-light m-2" disabled={isLoadingPreview || disableButton}   onClick={()=>{

          if(window.confirm("Do you want to cancel?")){
            navigate("/query");
            dispatch({ type: "ResetState"});
          }
        }}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default QueryBuilderFooter;
