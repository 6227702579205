import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import api from '../../api';
import { Spinner } from 'react-bootstrap';
import { MdClear } from "react-icons/md";

function UserQueryModal(props) {
    const [query, setQuery] = useState([]);
    const [filterQuery, setFilterQuery] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedQuery, setSelectedQuery] = useState(props.Selected);
    const [dataLoading, setDataLoading] = useState(true);
    const [loading, setLoading] = useState(true);

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const searchResult = () => {
        if (search.trim()) {
            const searchValue = query.filter((val) =>
                `${val.queryName}`.toLowerCase().includes(search.toLowerCase()),
            );
            if (searchValue?.length) {
                setFilterQuery(searchValue);
            } else {
                setFilterQuery([]);
            }
        } else {
            setFilterQuery([]);
        }
    };

    useEffect(() => {
        searchResult([]);
    }, [search]);

    const request = {
        resource: 'api/queries/get',
    };

    const fetchQuery = async () => {
        setLoading(true)

        try {
            const queryData = await api.crud.getList(request);
            setQuery(queryData.data.data.queries);
            setLoading(false);
        } catch (error) {
            console.error(error, 'error');
        }
    };

    useEffect(() => {
        fetchQuery();
    }, []);

    const selectHandler = (query) => {
        let tempChecked = [...selectedQuery]

        if (selectedQuery?.filter(ele => ele.QueryID === query.QueryID)?.length === 0) {
            tempChecked = [...tempChecked, query]
        }

        setSelectedQuery(tempChecked)


    }

    const handleRemove = (id) => {
        setSelectedQuery(value => value.filter(res => res.QueryID !== id))
    }

    const createrequest = {
        resource: 'api/queryusers/create/user'
    }

    const PostQueryData = async (data) => {
        try {
            await api.crud.create(createrequest, data)
        } catch (error) {
            console.error(error)
        }
    }

    const handleClick = async () => {
        const data = {
            data: selectedQuery.map(ele => {
                return {
                    [ele.QueryID]: props.userId,
                }
            }),
            id: props.userId
        }
        setDataLoading(false)
        await PostQueryData(data);
        props.fetchQueries()
        props.onHide();
        setDataLoading(true)
        setQuery([]);
    }

    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header
                    closeButton
                    closeVariant="dark"
                    className="bg-light text-dark fw-bold fs-5"
                >
                    Queries
                </Modal.Header>
                {
                    !loading ?
                        <>
                            <div className="mx-2">
                                <div className="d-flex flex-column postion-relative">
                                    <input
                                        type="search"
                                        class="form-control rounded"
                                        placeholder="Search"
                                        aria-label="Search"
                                        aria-describedby="search-addon"
                                        onChange={handleSearch}
                                    />
                                    {filterQuery?.length > 0 && 
                                        <div className='suggestionList'>
                                            {filterQuery.map((ele) => 
                                                <div className='User' onClick={() => selectHandler(ele)}>{ele.queryName}</div>
                                            )}
                                        </div>
                                    }
                                </div>
                            </div>

                            <Modal.Body>
                                <div
                                    style={{
                                        'max-height': '12em',
                                        'overflow-y': 'auto',
                                        'overflow-x': 'auto',
                                    }}
                                >
                                    {selectedQuery.map((col, index) => {
                                        return (
                                            <>

                                                <div className="d-flex justify-content-between align-items-center rounded-2 text-white border px-2 py-1  bg-success">
                                                    <div>
                                                        {col.queryName}

                                                    </div>
                                                    <MdClear onClick={() => handleRemove(col.QueryID)} />

                                                </div>


                                            </>
                                        )
                                    })}


                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant='primary'
                                    onClick={() => {
                                        handleClick();
                                    }}
                                    disabled={!dataLoading}
                                >
                                    {!dataLoading ? <div className='px-3' ><Spinner size="sm"></Spinner></div> : props.close}
                                </Button>
                            </Modal.Footer>
                        </> :
                        <div className='text-center my-4'>
                            <Spinner style={{ zIndex: 1 }}></Spinner>
                        </div>
                }
            </Modal>
        </>
    );
}

export default UserQueryModal;
