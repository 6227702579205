import React, { useState } from "react";
import SideNavBar from "../sidebar/sidebar";
import Navbar from "../navbar/navbar";
import { Row, Col } from "react-bootstrap";
import Main from "../main/main";
import "../sidebar/sidebar.scss";

export default function Layout({ children }) {
  const [showContent, setShowContent] = useState(false);

  return (
    <>
      <Navbar
        showContent={showContent}
        setShowContent={setShowContent}
      ></Navbar>
      <SideNavBar showContent={showContent} setShowContent={setShowContent} />
      <Row>
        <Col>
          <Main showContent={showContent}>{children}</Main>
        </Col>
      </Row>
    </>
  );
}
