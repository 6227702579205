import React from 'react'
import './process.css';
import data from './data.json';
import { useNavigate,useLocation } from 'react-router-dom';


function Process() {

    const {state} = useLocation();
    console.log(state.data);

  return (
    <div>
        <div class="stepper d-flex flex-column mt-5 ml-2">
        {data[state.data].map((data)=>{

            return <div class="d-flex mb-1">
        <div class="d-flex flex-column pr-4 align-items-center me-4">
            <div class="rounded-circle bg-primary text-white mb-1" style={{width:"20px", height:"20px", backgroundColor:"#3985e8"}}></div>
            <div class="h-100" style={{width:"2px",backgroundColor:"#3985e8"}}></div>
        </div>
        <div className=" border-1 p-4">
            <div className="d-flex justify-content-between">
                <h5 class="text1">{data.title}</h5>
                <div>
                            {data?.access?.[0]?.isAccess && (
                                <span className='text-success bg-success px-2 py-1 rounded mx-1 bg-opacity-25'>
                                {data?.access?.[0].label}
                                </span>
                            )}
                            {data?.access?.[1]?.isAccess && (
                                <span class='text-primary bg-primary px-2 py-1 rounded mx-1 bg-opacity-25'>
                                {data?.access?.[1].label}
                                </span>
                            )}
                            {data?.access?.[2]?.isAccess && (
                                <span class='text-danger bg-danger px-2 py-1 rounded mx-1 bg-opacity-25'>
                                {data?.access?.[2].label}
                                </span>
                            )}
                </div>
            </div>
            <p class="lead pb-3" style={{color:"#988f8f"}}>{data.description}</p>
        </div>
        </div>

        })}
        
  </div>
    </div>
  )
}

export default Process;