import React, { useState, useEffect } from 'react';
import '../userinfo/user.css';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import CryptoJS from 'crypto-js';

export default function EditUsers() {
    const authState = useSelector((state) => state.authUser);
    const navigate = useNavigate();
    const location = useLocation();

    const [fname, setFname] = useState(location.state.fname);
    const [lname, setLname] = useState(location.state.lname);
    const [status, setStatus] = useState(location.state.status);
    const [validity, setValidity] = useState(location.state.validity);
    const [secretkey, setSecretkey] = useState('');
    const [accesskey, setAccesskey] = useState(location.state.accesskey);
    const [loading, setLoading] = useState(false);
    // const [userDetails, setUserDetails] = useState({ created_by: authState.profile.user_id });
    // console.log(location.state)

    const stringdecryption = (enValue) => {

        const bytes = CryptoJS.AES.decrypt(enValue, process.env.REACT_APP_CRYPTO_SECRET);
        const decryptAccessToken = bytes.toString(CryptoJS.enc.Utf8);
        return decryptAccessToken;

    }
    useEffect(async () => {
        const request = {
            resource: 'api/webserviceusers/secretkey',
            id: location.state.id,
        };

        const response = await api.dataset.getDatasetById(request);

        // setSecretkey(response.data.data.secret_key);
        const decrypted_value = stringdecryption(response.data.data.secret_key)
        setSecretkey(decrypted_value);
        
    }, []);

    const onSubmit = async () => {
        const reqData = {
            first_name: fname,
            last_name: lname,
            validity: validity,
            is_active: status,
        };

        const response = await api.dataset.updateUser(
            {
                resource: 'api/webserviceusers/update',
                id: location.state.id,
            },
            { ...reqData },
        );

        if (response.status == 200) {
            navigate('/serviceuser');
            toastr.success('User updated successfully');
            setLoading(false);
        } else {
            toastr.error('Error while updating user');
            setLoading(false);
        }
    };

    return (
        <div className="cntr ms-5 ">
            {loading && (
                <div
                    class="d-flex justify-content-center mt-5"
                    style={{ marginTop: '300px', width: '800px' }}
                >
                    <div class="spinner-border" role="status">
                        {/* <span class="sr-only">Loading...</span> */}
                    </div>
                </div>
            )}
            {!loading && (
                <div
                    class="container rounded-5 mx-2 my-4 p-4 w-75 position-relative m-auto"
                    style={{
                        boxShadow: 'rgba(0,0,0,0.2) 0px 2px 8px 0px',
                        backgroundColor: 'whitesmoke',
                    }}
                >
                    <h4 className="fw-bold ms-4 text-center m-auto">
                        Edit User
                    </h4>
                    <div class="row">
                        <div class="col">
                            <div>
                                <form>
                                    {/* {beitems.map((data, index) => { */}
                                    <div className="d-flex">
                                        <div className="form-group w-50">
                                            <div className="p-2">
                                                <label htmlFor="exampleInputEmail1">
                                                    First Name
                                                </label>
                                                <input
                                                    className="form-control "
                                                    id="exampleInputEmail1"
                                                    placeholder=""
                                                    value={fname}
                                                    name="fname"
                                                    // disabled={flag}
                                                    // value={userDetails[data.name] || ""}
                                                    onChange={(e) => {
                                                        // handleChange(e);
                                                        setFname(e.target.value);

                                                        // setErrData({ ...errorData, errName:""});
                                                    }}
                                                />
                                            </div>
                                            <div className="p-2">
                                                <label htmlFor="exampleInputEmail1">
                                                    Last Name
                                                </label>
                                                <input
                                                    className="form-control "
                                                    id="exampleInputEmail1"
                                                    placeholder=""
                                                    value={lname}
                                                    name="lname"
                                                    // disabled={flag}
                                                    // value={userDetails[data.name] || ""}
                                                    onChange={(e) => {
                                                        // handleChange(e);
                                                        setLname(e.target.value);

                                                        // setErrData({ ...errorData, errName:""});
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="d-flex">
                                                <div className="form-group w-100">
                                                    <div className="p-2">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Status
                                                        </label>
                                                        <select
                                                            onChange={(e) => {
                                                                setStatus(e.target.value);
                                                            }}
                                                            name="status"
                                                            className="form-control mb-2"
                                                            value={status}
                                                        >
                                                            <option value={''}>
                                                                Select status
                                                            </option>
                                                            <option value="true">
                                                                Active
                                                            </option>
                                                            <option value="false">
                                                                Inactive
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className="p-2" style={{ marginTop: "-2%" }}>
                                                        <label id="demo-simple-select-label">
                                                            Validity
                                                        </label>
                                                        <select
                                                            onChange={(e) => {
                                                                setValidity(e.target.value);
                                                            }}
                                                            name="validity"
                                                            value={validity}
                                                            // disabled={flag}
                                                            className="form-control mb-2"
                                                        >
                                                            <option value={''}>
                                                                Select validity
                                                            </option>
                                                            <option
                                                                value={moment()
                                                                    .add(30, 'days')
                                                                    .format('yyyy-MM-DD')}
                                                            >
                                                                30 Days
                                                            </option>
                                                            <option
                                                                value={moment()
                                                                    .add(60, 'days')
                                                                    .format('yyyy-MM-DD')}
                                                            >
                                                                60 Days
                                                            </option>
                                                            <option
                                                                value={moment()
                                                                    .add(90, 'days')
                                                                    .format('yyyy-MM-DD')}
                                                            >
                                                                90 Days
                                                            </option>
                                                            <option
                                                                value={moment()
                                                                    .add(120, 'days')
                                                                    .format('yyyy-MM-DD')}
                                                            >
                                                                120 Days
                                                            </option>
                                                            <option
                                                                value={moment()
                                                                    .add(365, 'days')
                                                                    .format('yyyy-MM-DD')}
                                                            >
                                                                365 Days
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="exampleInputEmail1">
                                            Access Key
                                        </label>
                                        <input
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            value={accesskey}
                                            disabled
                                        />
                                        <label htmlFor="exampleInputEmail1">
                                            Password
                                        </label>
                                        <input
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            value={secretkey}
                                            disabled
                                        />
                                    </div>
                                </form>
                                <div className="d-flex justify-content-end">
                                    <button
                                        type="button"
                                        className="btn btn my-3  mt-4 mx-3"
                                        variant="outline-secondary"
                                        onClick={() => {
                                            navigate('/serviceuser');
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        variant="success"
                                        className="btn btn my-3   mt-4 px-3"
                                        style={{
                                            backgroundColor: 'rgb(73, 151, 73)',
                                            color: "white"
                                        }}
                                        onClick={(e) => {
                                            onSubmit();
                                            setLoading(true);
                                            navigate('/serviceuser');
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
