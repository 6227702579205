import { useEffect } from 'react';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import api from '../../api';
import { MdClear } from "react-icons/md";
export default function QueryUserModel(props) {
    const [selectedUsers, setSelectedUsers] = useState(props.Selected);
    const [users, setUsers] = useState([]);
    const [filteredusers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataLoading, setDataLoading] = useState(true);
    const [search, setSearch] = useState("");

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };
    const serchResult = () => {
        if (search.trim()) {
            const searchValue = users.filter(val => `${val.first_name} ${val.last_name} `.toLowerCase().includes(search.toLowerCase()))
            if (searchValue?.length) {
                setFilteredUsers(searchValue);
            } else {
                setFilteredUsers([]);
            }
        } else {
            setFilteredUsers([]);
        }
    }

    useEffect(() => {
        serchResult()
    }, [search])

    const handleClick = async () => {
        const data = { data: selectedUsers.map(ele => { return { [props.queryId]: ele.id } }), Id: props.queryId }
        setDataLoading(false)
        await PostUserData(data);
        props.fetchQueries()
        props.onHide();
        setDataLoading(true)
        setUsers([]);
    };
    const request = {
        resource: 'api/webserviceusers/getall',
    };
    const fetchUserTable = async () => {
        setLoading(true)
        try {
            const userData = await api.crud.getList(request);
            setUsers(userData.data.data.users)
            setLoading(false)
        } catch (error) {
            console.error(error);
        }
    };
    const selectHandler = (user) => {
        let tempChecked = [...selectedUsers];
        if (selectedUsers?.filter(ele => ele.id === user.id)?.length === 0) {
            tempChecked = [...tempChecked, user]
        }
        setSelectedUsers(tempChecked)
    }
    useEffect(() => {
        fetchUserTable();
    }, [])
    const createrequest = {
        resource: 'api/queryusers/create/query',
    };
    const PostUserData = async (data) => {
        try {
            await api.crud.create(createrequest, data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleRemove = (id) => {
        setSelectedUsers(value => value.filter(res => res.id !== id))
    }

    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header
                    closeButton
                    closeVariant="dark"
                    className="bg-light text-dark fw-bold fs-5"
                >
                    {props.heading}
                </Modal.Header>
                {
                    !loading ?
                        <>
                            <div className='mx-2'>
                                <div className="d-flex flex-column position-relative">
                                    <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" onChange={handleSearch} />
                                    {
                                        filteredusers?.length > 0 &&
                                        <div className='suggestionList'>
                                            {filteredusers.map((ele) => <div className='User' onClick={() => selectHandler(ele)} >{ele.first_name + ' ' + ele.last_name}</div>)}
                                        </div>
                                    }
                                </div>

                            </div>
                            <Modal.Body>
                                <div style={{ "max-height": "12em", "overflow-y": "auto", "overflow-x": "auto" }} >
                                    {selectedUsers.map((col, index) => {

                                        return (
                                            <>
                                                <div
                                                    className="d-flex justify-content-between align-items-center rounded-2 text-white border px-2 py-1  bg-success"

                                                >
                                                    <div>{col.first_name + " " + col.last_name}</div>
                                                    <MdClear onClick={() => handleRemove(col.id)} />
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        handleClick();
                                    }}
                                    disabled={!dataLoading}
                                >
                                    {!dataLoading ? <div className='px-3' ><Spinner size="sm"></Spinner></div> : props.close}
                                </Button>
                            </Modal.Footer>
                        </> :
                        <div className='text-center my-4'>
                            <Spinner style={{ zIndex: 1 }}></Spinner>
                        </div>
                }
            </Modal>

        </>
    );

}
