import {
  CrudService,
  UserService,
  AuthService,
  SourceListService,
  AzureService,
  AzureGraphService,
  VaultService,
  DatasetService,
} from "./services";
import { RoleService } from "./services/role";

export default class EdsApiClient {
  crud;
  user;
  auth;
  azure;
  azureGraphApi;
  sourceList;
  vault;
  dataset;
  store = {
    apiDomain: "",
    token: undefined,
  };

  constructor(apiDomain) {
    this.store.apiDomain = apiDomain;
    this.crud = new CrudService(this.store);
    this.user = new UserService(this.store);
    this.auth = new AuthService(this.store);
    this.sourceList = new SourceListService(this.store);
    this.rolelist = new RoleService(this.store);
    this.azure = new AzureService(this.store);
    this.azureGraphApi = new AzureGraphService();
    this.vault = new VaultService(this.store);
    this.dataset = new DatasetService(this.store);
  }

  setToken(token) {
    this.store.token = token;
  }
}
