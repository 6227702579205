import CryptoJS from "crypto-js";

/**
 * Encrypts a string using AES (Advanced Encryption Standard).
 * @param {string} value - The string to encrypt.
 * @returns {string} The encrypted string.
 */
const StringEncryption = (value) => {
  return CryptoJS.AES.encrypt(
    value,
    process.env.REACT_APP_CRYPTO_SECRET
  ).toString();
};
const stringdecryption = (value) => {
  const bytes = CryptoJS.AES.decrypt(
    value,
    process.env.REACT_APP_CRYPTO_SECRET
  );

  const decryptAccessToken = bytes.toString(CryptoJS.enc.Utf8);

  return decryptAccessToken;
};
export { StringEncryption, stringdecryption };
