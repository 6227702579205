import React from "react";
import { useState } from "react";
import { Navbar, Container, Nav, Dropdown } from "react-bootstrap";
// import RenderOffCanvas from "../offcanvas/offcanvas";
import { FaBars, FaUser } from "react-icons/fa";
import { TfiClose } from 'react-icons/tfi';

import "./navbar.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AzureLogout } from "../../containers/auth/AzureLogin";
// import api from '../../api';

function Navigationbar({ showContent, setShowContent }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [active, setActive] = useState(false)

  const handleShow = () => setShowContent(!showContent);
  const navigate = useNavigate();
  // const [active, setActive] = useState(false);

  // const theme = useSelector((state) => state.themesSettings);
  const authState = useSelector((state) => state.authUser);

  return (
    <>
      <Navbar
        variant="light"
        className="d-flex justify-content-between"
        style={{
          background: "#ffffff",
        }}
      >
        <Container className="mx-0">
          <div className="d-flex align-items-center">
            {showContent ?
              <TfiClose onClick={handleShow}></TfiClose> :

              <FaBars
                size={"1.4em"}
                role="button"
                className="d-block"
                onClick={handleShow}
              />
            }
            <Navbar.Brand
              className="ms-2 brand fw-bolder"
              onClick={() => navigate("/")}
            >
              <h4 className="m-0">
                <span className="fw-bolder" id="brandpre">
                  Data
                </span>{" "}
                <span className="fw-bolder">Sharing</span>
              </h4>
            </Navbar.Brand>
          </div>
        </Container>
        <Nav className="me-4">
          <div className="d-md-flex d-md-block d-none align-items-center">
            <div
              className="btn btn-light me-2"
              role="button"
              onClick={() => {
                navigate('/processFlow')
              }}

            >
              <span className="text-nowrap me-2 fs-6" >
                Process flow
              </span>
            </div>
            <FaUser size={"1.4em"} className="m-1" />

            <Dropdown>
              <Dropdown.Toggle variant="white">
                {authState.profile.display_name}
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item href="#/action-1">
                  <AzureLogout />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Nav>
      </Navbar>
      {/* <RenderOffCanvas
        name="side bar"
        handleClose={handleClose}
        show={show}
        placement={"start"}
      /> */}
    </>
  );
}

export default Navigationbar;
