import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function ModalPage(props) {
    const [loading, setIsLoading] = useState(true);
    const handleClick = async () => {
        if (props.id && props.action){
            setIsLoading(false)
            await props.action(props.id);
            setIsLoading(true)
            props.onHide();
        } else {
            props.onHide();
        }
    };
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header
                closeButton={loading}
                closeVariant="white"
                className="bg-dark text-light"
            >
                {props.heading}
            </Modal.Header>
            <Modal.Body>
                <p>{props.body}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide} disabled={!loading}>
                    {props.failure}
                </Button>
                <Button
                    variant="danger"
                    onClick={() => {
                        handleClick();
                    }}
                    disabled={!loading}
                >
                    {!loading ? <div className='px-3' ><Spinner size="sm"></Spinner></div> : props.success}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
