import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function ViewUrlModal(props) {

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header
                closeButton
                closeVariant="dark"
                className="bg-light text-dark fw-bold fs-5"
            >
                {props?.heading || ""}
            </Modal.Header>
            <Modal.Body>
                {/* {props.body} */}
                <div>
                    {props.copyUrl}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant={props.isCopied ? "success" : "secondary"}
                    onClick={() => {
                        props.handleCopyClick(props.copyUrl);
                    }}
                >
                    {props.isCopied ? "Copied!" : "Copy"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
