import React, { useState, useEffect } from 'react';
import './serviceuser.scss';
import api from '../../api';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaEdit, FaTrashAlt, FaRegEye } from 'react-icons/fa';
import { IoPersonAddSharp } from 'react-icons/io5';
import SearchBar from '../../components/searchBar/searchBar';

import { FaInfoCircle } from "react-icons/fa"
import { useNavigate } from 'react-router-dom';
import TableSkeletonLoader from '../../components/loader/table-skeleton-loader/table-skeleton-loader';
import Limitdropup from '../../components/limit-drop-up/limit-drop-up';
import Pagination from '../../components/pagination/pagination';
import QueryConfigurationTable from '../../components/tablewithpaginationandlimit/configuration-table';
import ModalPage from '../../components/modal/modal';
import { toastr } from 'react-redux-toastr';
import NotFound from '../notfound';

const Serviceuser = () => {

    const [user, setUser] = useState([]);
    const [infoModel, setInfoModel] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [filteredusers, setFilteredUsers] = useState([]);
    const [filter, setFilter] = useState();
    const [sort] = useState([]);
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [filterData, setFilterData] = useState();
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [emptyFilter, setEmptyFilter] = useState(false);
    const [emptySearchbar, setEmptySearchbar] = useState(false);
    const [bulkDelete, setBulkDelete] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [deleteLoading, setDeleteLoading] = useState(true);
    const [isCopied, setIsCopied] = useState(false);
    const [copyUrl, setCopyUrl] = useState('');
    const [showUrlModal, setShowUrlModal] = useState(false);
    const navigate = useNavigate();

    // const request = {
    //     resource: 'api/queries',
    //     limit,
    //     currentPage,
    //     search,
    //     sort,
    //     filter,
    // };

    // for limit
    const handlingLimit = (limit) => {
        setLimit(limit);
        setCurrentPage(1);
    };

    //for page change
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    //for sort

    //for search
    const handleSearch = (value) => {
        setEmptyFilter(!emptyFilter);
        setFilter({
            dataSource: [],
            checktypes: [],
        });
        setSearch(value);
        setCurrentPage(1);
    };

    // const searchResult = async () => {
    //     if (search.trim()) {
    //         // const searchValue = userlist.filter(val => `${val.first_name} ${val.last_name} `.toLowerCase().includes(search.toLowerCase()))
    //         const searchValue = await searchData(search)
    //         if (searchValue?.length) {

    //             setUser(searchValue);
    //         } else if (searchValue == "") {
    //             setUser(user);
    //             setIsLoading(false)

    //         }
    //     } else {
    //         searchData();
    //         setUser(user);
    //         setIsLoading(false)

    //     }
    // }

    // useEffect(() => {
    //     searchResult()
    // }, [search])


    const handleDelete = async () => {
        try {
            const resource = {
                resource: 'api/webserviceusers/delete',
                id: deleteId,
            };

            let res = await api.dataset.deleteUser(resource);
            if (res.data.status === 200) {
                toastr.success('User deleted successfully');
                fetchUserTable()
            } else {
                return toastr.error('Error', 'Error while delete');

            }
        } catch (e) {
            toastr.error('Error while delete');
        }
    };

    // const searchData = async (val) => {

    //     const request = {
    //         resource: `api/webserviceusers`,
    //         search: val
    //     };

    //     const response = await api.dataset.getDataset(
    //         request,

    //     );
    //     setUser(response?.data?.data?.users?.rows)
    //     setIsLoading(true);
    // }


    const columns = [
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Access Key',
            accessor: 'access_key',
        },
        {
            Header: 'Status',
            accessor: 'activity',
            sortable: true,

            Cell: ({ row }) => (
                <div className="d-flex flexx-row gap-1">
                    {row.original.is_active === true ? 'Active' : 'Inactive'}
                </div>
            ),
        },
        {
            Header: 'Action',
            Cell: ({ row }) => (
                <div className="d-flex flexx-row gap-1" style={{ cursor: "pointer" }}>
                    <FaInfoCircle
                        onClick={() => {
                            setInfoModel(true)
                        }}

                    ></FaInfoCircle>
                    <FaEdit
                        className="text-dark mx-2 font-size"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                            let value = row.values;
                            let original = row.original;
                            navigate('/editUsers', {
                                state: {
                                    fname: value.first_name,
                                    lname: value.last_name,
                                    id: original.id,
                                    status: original.is_active,
                                    validity: original.validity,
                                    accesskey: original.access_key,
                                },
                            });
                        }}
                    ></FaEdit>
                    <FaTrashAlt
                        className="text-dark mx-2 font-size"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            console.info({row})
                            setDeleteId(row.original.id);
                            setModalShow(true);
                        }}
                    ></FaTrashAlt>
                </div>
            ),
        },
    ];
    const nestedColumns = [
        {
            Header: 'Shared to Queries',
            accessor: 'queryName',
        },
    ];

    const fetchUserTable = async () => {


        try {

            const request = {
                resource: "api/webserviceusers",
                limit,
                currentPage,
                search,
                sort,
                filter,
            }
            setIsLoading(true);
            const response = await api.dataset.getDataset(request);

            setUser(response.data.data.users.rows);

            setFilteredUsers(response.data.data.users.rows)
            setPages(response?.data?.data?.totalPages);
            setCurrentPage(response?.data?.data?.currentPage);
            setIsLoading(false);

        } catch (e) {
            setIsLoading(false);
        }
    };

    // useEffect(async () => {
    //     try {
    //         setIsLoading(true);

    //         const request = {
    //             resource: "api/webserviceusers"
    //         }
    //         const response = await api.dataset.getDataset(request);

    //         setUser(response.data.data.users.rows);
    //         setFilteredUsers(response.data.data.users.rows)
    //         setIsLoading(false);
    //         // axios.get(get_user).then((response) => {
    //         //         setUserList(response.data.data);
    //         //         console.log("val", response.data.data)
    //         //     })
    //     } catch (e) {
    //         setIsLoading(false);
    //     }

    // }, [deleted])

    useEffect(() => {
        fetchUserTable();
    }, [limit, currentPage, sort, search, filter]); //eslint-disable-line react-hooks/exhaustive-deps
    return (
        <Container>
            <>
                <h5 className="text-dark pt-4 fw-bold">Service Users</h5>

                <ModalPage
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    heading={'Delete Confirmation'}
                    body={'Are you sure you want to delete this User?'}
                    success={'Delete'}
                    failure={'Cancel'}
                    id={deleteId}
                    action={handleDelete}
                />
                <ModalPage
                    show={infoModel}
                    onHide={() => setInfoModel(false)}
                    heading={'Information'}
                    body={'Configure access key,password and pass it to the Api.'}
                    success={'Okay'}
                    failure={'Cancel'}
                />

                <div className="d-flex justify-content-end" style={{ marginTop: "-4%" }}>
                    {/* <h5 className="text-dark pt-4 fw-bold">Users</h5> */}

                    <div className="d-flex gap-2 mt-3 mb-3">
                        <button
                            type="button"
                            className="btn text-light custom_button d-flex"
                            style={{
                                backgroundColor: 'rgb(73, 151, 73)',
                            }}
                            onClick={() => {
                                navigate('/serviceuser/addUsers', { state: { data: user } });
                            }}
                        >
                            <div className="add-user">
                                <div className="icons">
                                    <IoPersonAddSharp
                                        size={'15px'}
                                        style={{
                                            marginLeft: '7px',
                                            marginBottom: '4px',
                                        }}
                                    /> <span>Add User</span>

                                </div>

                            </div>
                        </button>



                    </div>
                </div>

                <Row>
                    <Col md={4} className="my-1">
                        {
                            user?.length > 0 &&
                            <SearchBar
                                handleSearch={handleSearch}
                                emptySearchbar={emptySearchbar}
                            ></SearchBar>
                        }
                    </Col>
                    <Col md={8}>
                        {/* <Filters
                            filterData={filterData}
                            handleFilter={handleFilter}
                            emptyFilter={emptyFilter}
                            statusData={['Active', 'Inactive']}
                        ></Filters> */}
                    </Col>
                </Row>

                {isLoading ? (
                    <TableSkeletonLoader
                        intialrows={8}
                        initalcolumns={5}
                    ></TableSkeletonLoader>
                ) : (
                    <>
                        {
                            user?.length > 0 ?
                                <>
                                    <div style={{ height: "500px", overflowY: "auto" }}>
                                        <QueryConfigurationTable
                                            row={user}
                                            columns={columns}
                                            nestedColumns={nestedColumns}
                                            // buttonColumns={buttonColumns}
                                            buttonColumns={[]}
                                            isPagination={false}
                                            isLimit={false}
                                            pages={pages}
                                            limit={limit}
                                            fetchQueries={fetchUserTable}
                                        // handlingLimit={handlingLimit}
                                        // handlePageChange={handlePageChange}
                                        // currentPage={currentPage}
                                        ></QueryConfigurationTable>
                                    </div>

                                    <div className="d-flex justify-content-end  w-100 pagination-scrollbar ">
                                        <Row>
                                            <div className="d-flex  mx-3">
                                                <div className="py-2 font-weight-semibold">
                                                    Records per page
                                                </div>
                                                <div className=" px-1">
                                                    <Limitdropup
                                                        limit={limit}
                                                        handlingLimit={handlingLimit}
                                                    ></Limitdropup>
                                                </div>
                                            </div>
                                        </Row>

                                        <div className="py-1">
                                            <Pagination
                                                currentPage={currentPage}
                                                handlePageChange={handlePageChange}
                                                pages={pages}
                                            ></Pagination>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => handleSearch("")}
                                    >
                                        Back
                                    </button>
                                    <NotFound message="No Users found" />
                                </>
                        }
                    </>
                )}
            </>
        </Container>
    );
};

export default Serviceuser;
