import React, { useState } from 'react';
import {Row} from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import Limitdropup from '../../components/limit-drop-up/limit-drop-up';
import Pagination from '../../components/pagination/pagination';
import DynamicUserTable from '../../components/tablewithpaginationandlimit/user-table';
import { useNavigate } from 'react-router-dom';
import { FaTrashAlt } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa"
import TableSkeletonLoader from '../../components/loader/table-skeleton-loader/table-skeleton-loader';
import api from '../../api';
import { toastr } from 'react-redux-toastr';
import ModalPage from '../../components/modal/modal';
import { useSelector } from 'react-redux';

function Userslist({ data, admin, setDeleted, deleted, isLoading }) {
    const authState = useSelector((state) => state.authUser)
    const [pages, setPages] = useState(0);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [modalShow, setModalShow] = useState(false);
    const [infoModel, setInfoModel] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [loading, setLoading] = useState(true);


    const defaultColumns = [
        {
            Header: 'First Name',
            accessor: 'first_name',
        },
        {
            Header: 'Last Name',
            accessor: 'last_name',
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Access Key',
            accessor: 'access_key',
        },
        {
            Header: 'Status',
            accessor: 'activity',
            sortable: true,

            Cell: ({ row }) => (
                <div className="d-flex flexx-row gap-1">
                    {row.original.is_active === true ? 'Active' : 'Inactive'}
                </div>
            ),
        },        
    ];

    const buttonColumns = [{
        Header: 'Action',
        Cell: ({ row }) =>  (
            <div className="d-flex flexx-row gap-1">
                <FaInfoCircle
                        onClick={()=> {
                            console.log(row)
                            setInfoModel(true)
                        }}

                ></FaInfoCircle>
                <FaEdit
                    className="text-dark mx-2 font-size"
                    onClick={(e) => {
                        let value = row.values;
                        let original = row.original;
                        navigate('/editUsers', {
                            state: {
                                fname: value.first_name,
                                lname: value.last_name,
                                id: original.id,
                                validity: original.validity,
                                accesskey: original.access_key,
                            },
                        });
                    }}
                ></FaEdit>
                <FaTrashAlt
                    className="text-dark mx-2 font-size"
                    onClick={() => {
                        setDeleteId(row.original.id);
                        setModalShow(true);
                    }}
                ></FaTrashAlt>
            </div>
        ),
    }]

    const columns = authState?.profile.role_id === 1 ? [...defaultColumns, ...buttonColumns] : defaultColumns;

    const handlingLimit = (limit) => {
        // setLimit(limit);
        // setCurrentPage(1);
        // console.log("fxghbf")
        // axios.patch(
        //   `http://localhost:8004/api/webserviceusers/?page=1&limit=10&search=vks`,{
        //    "deleted_by":admin
        //    }).then((response) => {
        //      console.log(response.data.status)
        //      if(response.data.status == "200")
        //      {
        //        setDeleted(!deleted)
        //      }
        //       console.log("val",response)
        //   })
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleDelete = async () => {
        try {
            const resource = {
                resource: 'api/webserviceusers/delete',
                id: deleteId,
            };

            let res = await api.dataset.deleteUser(resource);
            if (res.status !== 200) {
                return toastr.error('Error', res?.message);
            } else {
                toastr.success('User deleted successfully');

                setDeleted(!deleted);
            }
        } catch (e) {
            toastr.error('Error while deleted');
        }
    };
    
    return (
        
        <div>
          {!isLoading &&
                    <TableSkeletonLoader
                        intialrows={2}
                        initalcolumns={6}
                    ></TableSkeletonLoader>}
                
            {isLoading && <DynamicUserTable
                row={data}
                columns={columns}
                isLimit={false}
                pages={pages}
                limit={limit}
            />}
            <div className="d-flex justify-content-end  mx-auto  w-100 ">
                <Row>
                    <div className="d-flex  mx-3">
                        <div className="py-2 font-weight-semibold">
                            Records per page
                        </div>
                        <div className=" px-1">
                            <Limitdropup
                                limit={limit}
                                handlingLimit={handlingLimit}
                            ></Limitdropup>
                        </div>
                    </div>
                </Row>

                <div className="py-1">
                    <Pagination
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        pages={pages}
                    ></Pagination>
                </div>
            </div>

            <ModalPage
                show={modalShow}
                onHide={() => setModalShow(false)}
                heading={'Delete Confirmation'}
                body={'Are you sure to delete this User?'}
                success={'Delete'}
                failure={'Cancel'}
                id={deleteId}
                action={handleDelete}
            />
            <ModalPage
                show={infoModel}
                onHide={() => setInfoModel(false)}
                heading={'Information'}
                body={'Configure access key,password and pass it to the Api.'} 
                success={'Okay'}
                failure={'Cancel'}
            />
        </div>
    );
}

export default Userslist;
