import React, { useState, useEffect } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
// import {dataSet} from "./dataSet"
import ListTable from "./ListTable/ListTable";
// import TableComponent from "./TableComponent/TableComponent";
import { toastr } from 'react-redux-toastr';
import Dropdown from '../form-controls/Dropdown';
import Input from '../form-controls/Input';
import api from "../../api";
import { RouteKeys } from "../../containers/routes/route-keys";

function EditDataset() {

  const params = useParams();
  const navigate = useNavigate();
  const [checked, setChecked] = useState([]);
  const [unChecked, setUnChecked] = useState([]);
  const [textInput, setTextInput] = useState([]);
  const [dsValue, setDsValue] = useState(null);
  const [status, setStatus] = useState(null);
  const [tablesList, setTablesList] = useState([]);
  // const [sourceDataList, setSourceDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [error, setError] = useState({
    tables: ""
  });

  useEffect(() => {
    // const fetchSourceList = async () => {
    //   try {
    //     const sourceList = await api.sourceList.getSourceList({
    //       resource: 'api/vault',
    //     });
    //     console.info({ sourceList })
    //     setSourceDataList(sourceList.data?.data?.keys);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };
    const getDatasetById = async () => {
      try {
        setIsLoading(true);
        const datasetById = await api.dataset.getDatasetById({
          resource: 'api/datasets/find',
          id: params.id,
        });
        // console.info({ datasetById })
        if (datasetById.data.status !== 200) {
          setIsLoading(false);
          return toastr.error("Error", datasetById?.data.message);
        } else {
          setIsLoading(false);
        }
        if (datasetById?.data?.data?.Datasource_Name) {
          const fetchTableList = async () => {
            try {
              setIsTableLoading(true);
              const reqData = {
                "Datasource_Name": datasetById?.data?.data?.Datasource_Name,
                "type": "noColumns"
              }
              const tableData = await api.dataset.getDatasourceTables({
                resource: 'api/datasets/tables',
              }, { ...reqData });
              if (tableData && tableData.data.status !== 200) {
                setIsTableLoading(false);
                return toastr.error("Error", tableData?.data.message);
              }
              setTablesList(tableData?.data?.data);
              setIsTableLoading(false);
            } catch (error) {
              console.error(error);
            }
          };
          fetchTableList();
        }
        setTextInput(datasetById.data.data.DatasetName);
        setDsValue(datasetById.data.data.Datasource_Name);
        setStatus(datasetById.data.data.isActive ? "Active" : "InActive");
        setUnChecked(datasetById?.data?.data?.setentities);
        setChecked(datasetById?.data?.data?.setentities.map(tab => tab.entityName) || []);
        // setSourceDataList(sourceList.data?.data?.keys);
      } catch (error) {
        console.error(error);
      }
    };
    // fetchSourceList();
    getDatasetById();
  }, []);

  // const findMissingItems = () => {
  //   let missingItems = [];
  //   let checkedItems = [...checked]

  //   for (let i = 0; i < unChecked.length; i++) {
  //     if (!checked.includes(unChecked[i].entityName)) {
  //       missingItems.push(unChecked[i].id);
  //       checkedItems.filter(item => item != unChecked[i].entityName);
  //     } else {
  //       checkedItems.filter(item => item != unChecked[i].entityName)
  //     }
  //   }

  //   return { missingItems, checkedItems };
  // }

  const saveDataset = async () => {
    // const { missingItems, checkedItems } = findMissingItems();
    // console.info({ missingItems, checkedItems });
    // console.info({checked})
    try {
      setIsLoading(true);
      const reqData = {
        "selected": checked,
        // "unselected": missingItems,
        "isActive": status === "Active" ? true : false,
      }
      // console.info({ reqData });
      const editDataResponse = await api.dataset.updateDataset({
        resource: 'api/datasets/update',
        id: params.id
      }, {...reqData});
      if (editDataResponse.status !== 200){
        setIsLoading(false)
        return toastr.error("Error", editDataResponse?.data.message);
      } else {
        setIsLoading(false)
        navigate(RouteKeys.Dataset);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-around">
        <h5 className="text-dark text-center pt-4 fw-bold">Edit Dataset</h5>
      </div>
      <div className="mx-5 my-3">
        {isLoading && (
          <Spinner
            className="position-absolute opacity-100 top-50 start-50"
            style={{ zIndex: 1 }}
          ></Spinner>
        )}
        <Input
          // {...field}
          label="Dataset Name"
          type="text"
          isMandatory={true}
          disabled={true}
          value={textInput}
          style={{
            border: 'none',
            borderBottom:
              '1px solid lightgrey',
            backgroundColor:
              'transparent',
            borderRadius: 'unset',
            cursor: "not-allowed"
          }}
          className="line-input"
        // onChange={(e) => {
        //   console.info({ti: e.target.value})
        //   setTextInput(e.target.value)
        // }

        // }
        />
        <Row>
          <Col xs={8}>

            {/* <Dropdown
          id="table_name"
          label="Data Source"
          isMandatory={true}
          disabled={isLoading}
          busy={
            sourceDataList?.length > 0
              ? false
              : true
          }
          value={dsValue}
          data={sourceDataList}
          onChange={(value) => {
            setDsValue(value)
          }}
        /> */}
            <Input
              // {...field}
              label="Data Source"
              type="text"
              isMandatory={true}
              disabled={true}
              value={dsValue}
              style={{
                border: 'none',
                borderBottom:
                  '1px solid lightgrey',
                backgroundColor:
                  'transparent',
                borderRadius: 'unset',
                cursor: "not-allowed"
              }}
              className="line-input"
            />
          </Col>
          <Col xs={4}>

            <Dropdown
              id="table_name"
              label="Status"
              isMandatory={true}
              disabled={isLoading}
              value={status}
              data={["Active", "InActive"]}
              onChange={(value) => {
                setStatus(value)
              }}
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
            <ListTable
              checked={checked}
              setChecked={setChecked}
              tablesList={[...unChecked.map(tab => tab.entityName), ...tablesList]}
              isLoading={isLoading}
              isTableLoading={isTableLoading}
              error={error}
              setError={setError}
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button
            type="button"
            onClick={() => navigate(RouteKeys.Dataset)}
            className="btn btn my-3  mt-4 mx-3 "
            variant="outline-secondary"
            size="md"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            type="submit"
            variant="success"
            className="btn btn my-3   mt-4 px-3"
            style={{
              backgroundColor: 'rgb(73, 151, 73)',
            }}
            onClick={() => {
              if (checked?.length) {
                saveDataset();
              } else {
                if (!checked?.length) {
                  setError({
                    ...error, tables: "Please select atleast one table"
                  })
                }
              }
            }}
          >
            <>{'Save'}</>
          </Button>
        </div>
      </div>
    </>
  );
}

export default EditDataset;

