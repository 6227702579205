import React from 'react';
import { useState } from 'react';
// import RenderOffCanvas from "../offcanvas/offcanvas";

import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FiUsers } from "react-icons/fi";
// import { MdDvr } from "react-icons/md";
import { RiDatabaseFill } from "react-icons/ri"
import { MdDateRange } from "react-icons/md";
import { CiUser } from "react-icons/ci";
import { MdQueryBuilder } from "react-icons/md";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

export default function SideNavBar({ name, handleClose, show, ...props }) {
  const authState = useSelector((state) => state.authUser);

  const { showContent, setShowContent } = props;

  const handleShow = () => setShowContent(!showContent);
  const renderTooltip = (props, name) => <Tooltip {...props}>{name}</Tooltip>;

  return (
    <div
      className=" position-absolute d-none d-md-block"
      style={{
        height: '93.3vh',
        // width: "150px",
        background: 'rgb(33, 37, 41)',
      }}
    >
      <Container>
        {/* <Row>
          <Col>
            <NavLink to="">
              {({ isActive }) => (
                <div className="menu">
                  <span className="icon">
                    <FaBars
                      size={"1.4em"}
                      role="button"
                      className="d-block"
                      onClick={handleShow}
                    />
                  </span>
                  <span className="ict"></span>
                </div>
              )}
            </NavLink>
          </Col>
        </Row> */}
        {/* <Row style={{ marginTop: '25px' }}>
                    <Col>
                        <Row>
                            <Col>
                                <label
                                    for="touch"
                                    className="d-flex gap-2 ms-2"
                                >
                                    <MdOutlineDashboardCustomize
                                        className="text-light"
                                        size={'1.5em'}
                                    ></MdOutlineDashboardCustomize>
                                    <div className="text-light titlename ">
                                        Dashboard
                                    </div>
                                </label>
                                <input type="checkbox" id="touch"></input>
                                <div className="slide">
                                    <NavLink to="/dashboard/freshness">
                                        {({ isActive }) => (
                                            <div
                                                color={
                                                    isActive
                                                        ? 'rgb(90, 238, 90)'
                                                        : null
                                                }
                                            >
                                                Freshness
                                            </div>
                                        )}
                                    </NavLink>
                                    <NavLink to="/dashboard/schema">
                                        {({ isActive }) => (
                                            <div
                                                color={
                                                    isActive
                                                        ? 'rgb(90, 238, 90)'
                                                        : null
                                                }
                                            >
                                                Schema
                                            </div>
                                        )}
                                    </NavLink>
                                    <NavLink to="/dashboard/volume">
                                        {({ isActive }) => (
                                            <div
                                                color={
                                                    isActive
                                                        ? 'rgb(90, 238, 90)'
                                                        : null
                                                }
                                            >
                                                Volume
                                            </div>
                                        )}
                                    </NavLink>
                                    <NavLink to="/dashboard/distribution">
                                        {({ isActive }) => (
                                            <div
                                                color={
                                                    isActive
                                                        ? 'rgb(90, 238, 90)'
                                                        : null
                                                }
                                            >
                                                Distribution
                                            </div>
                                        )}
                                    </NavLink>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row> */}
        {/* {authState.profile.role_id === 1 ? (
                    <Row style={{ marginTop: '35px' }}>
                        <Col>
                            <Row>
                                <Col>
                                    <label
                                        for="stouch"
                                        className="d-flex gap-2 ms-2"
                                    >
                                        <IoSettingsSharp
                                            className="text-light"
                                            size={'1.5em'}
                                        ></IoSettingsSharp>
                                        <div className="text-light titlename ">
                                            Settings
                                        </div>
                                    </label>
                                    <input type="checkbox" id="stouch"></input>
                                    <div className="slide settingsSlide">
                                        <NavLink
                                            to={
                                                RouteKeys.AddConfigurationSettings
                                            }
                                        >
                                            {({ isActive }) => (
                                                <div
                                                    color={
                                                        isActive
                                                            ? 'rgb(90, 238, 90)'
                                                            : null
                                                    }
                                                >
                                                    Config
                                                </div>
                                            )}
                                        </NavLink>
                                        <NavLink to={RouteKeys.DataSource}>
                                            {({ isActive }) => (
                                                <div
                                                    color={
                                                        isActive
                                                            ? 'rgb(90, 238, 90)'
                                                            : null
                                                    }
                                                >
                                                    Data Source
                                                </div>
                                            )}
                                        </NavLink>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                ) : null} */}
        {authState.profile.role_id === 1 ? (
          <Row>
            <Col>
              <NavLink
                className={(nav) => (nav?.isActive ? "active" : null)}
                to="/users"
              >
                {({ isActive }) => (
                  <div className="menu" onClick={handleShow}>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 400 }}
                      overlay={(props) => renderTooltip(props, 'Users')}
                    >
                      <span className="icon">
                        <FiUsers
                          size={"1.5em"}
                          color={isActive ? "rgb(90, 238, 90)" : null}
                        />
                      </span>
                    </OverlayTrigger>
                    {showContent && <span className="ict">Users</span>}
                  </div>
                )}
              </NavLink>
            </Col>
          </Row>
        ) : null}
        {authState.profile.role_id === 1 ? (
          <Row>
            <Col>
              <NavLink
                className={(nav) =>
                  nav?.isActive ? 'active' : null
                }
                to='/serviceuser'
              >
                {({ isActive }) => (
                  <div className="menu" onClick={handleShow}>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 400 }}
                      overlay={(props) => renderTooltip(props, 'ServiceUsers')}
                    >
                      <span className="icon">
                        <CiUser
                          size={'1.5em'}
                          color={
                            isActive
                              ? 'rgb(90, 238, 90)'
                              : null
                          }
                        />
                      </span>
                    </OverlayTrigger>
                    {showContent && <span className="ict">ServiceUsers</span>}
                  </div>
                )}
              </NavLink>
            </Col>
          </Row>
        ) : null}
        {authState.profile.role_id === 1 ? (
          <Row>
            <Col>
              <NavLink
                className={(nav) => (nav?.isActive ? "active" : null)}
                to="/dataSource"
              >
                {({ isActive }) => (
                  <div className="menu" onClick={handleShow}>
                    <OverlayTrigger
                      placement='top'
                      delay={{ show: 250, hide: 400 }}
                      overlay={(props) => renderTooltip(props, 'Datasource')}
                    >
                      <span className="icon">
                        <MdDateRange
                          size={"1.5em"}
                          color={isActive ? "rgb(90, 238, 90)" : null}
                        />
                      </span>
                    </OverlayTrigger>
                    {showContent && <span className="ict">Datasource</span>}
                  </div>
                )}
              </NavLink>
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col>
            <NavLink
              className={(nav) => (nav?.isActive ? "active" : null)}
              to="/dataset"
            >
              {({ isActive }) => (
                <div className="menu" onClick={handleShow}>
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip(props, 'Dataset')}
                  >
                    <span className="icon">
                      <RiDatabaseFill
                        size={"1.5em"}
                        color={isActive ? "rgb(90, 238, 90)" : null}
                      />
                    </span>
                  </OverlayTrigger>
                  {showContent && <span className="ict">Dataset</span>}
                </div>
              )}
            </NavLink>
          </Col>
        </Row>
        <Row>
          <Col>
            <NavLink
              className={(nav) => (nav?.isActive ? "active" : null)}
              to="/query"
            >
              {({ isActive }) => (
                <div className="menu" onClick={handleShow}>
                  <OverlayTrigger
                    placement='top'
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => renderTooltip(props, 'Queries')}
                  >
                    <span className="icon">
                      <MdQueryBuilder
                        size={"1.5em"}
                        color={isActive ? "rgb(90, 238, 90)" : null}
                      />
                    </span>
                  </OverlayTrigger>
                  {showContent && <span className="ict">Queries</span>}
                </div>
              )}
            </NavLink>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
