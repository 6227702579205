import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import ListTable from "./ListTable/ListTable";
import TableComponent from "./TableComponent/TableComponent";
import { toastr } from 'react-redux-toastr';
import api from "../../api";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import QueryBuilderFooter from "./Footer/queryBuilderFooter";
import "./queryBuilder.scss"

function QueryBuilder() {
  const [checked, setChecked] = useState({});
  const [textInput, setTextInput] = useState({});
  const [dragContent, setDragContent] = useState(null);
  const [datasets, setDatasets]= useState();

  const {tableData, sortValues, sortKeyvalue}= useSelector((state) => state.queryBuilder)
  const dispatch = useDispatch();
  
  useEffect(async () => {


    try{
    dispatch({ type: "ResetState"});
    
    const request ={
      resource: 'api/datasets',
      from: 'query'
    }
    dispatch({type:"DatasetLoadingResponse", payload:false})


    const datasetResponse = await api.dataset.getDataset(request);

    if(datasetResponse){
    setDatasets(datasetResponse.data.data.rows);
    dispatch({type:"FilteredDatasetResponse", payload:datasetResponse.data.data.rows})
    dispatch({type:"DatasetLoadingResponse", payload:true})
    }else{
      setDatasets([]);
      dispatch({type:"FilteredDatasetResponse", payload:[]})
      dispatch({type:"DatasetLoadingResponse", payload:true})
    }
  } catch{
    dispatch({type:"DatasetLoadingResponse", payload:true})
  }


  }, [])
  
  // Drag Start Component
  const handleDragStart = (event) => {
    setDragContent(event.target);
  };



  // Drop Component
  const handleDrop = async(event) => {
    event.preventDefault();
    if (dragContent && dragContent.tagName === "DIV") {
      const datasetId= dragContent.dataset.datasetId
      const datasetName= dragContent.dataset.datasetName
      const datasetTableName=dragContent.dataset.tableName
      const tableName = datasetTableName
      const dataSourceName= dragContent.dataset.datasourceName
      const tableId = dragContent.dataset.tableId

      tableApiFunction(datasetId, datasetName, tableName,dataSourceName, tableId)
    }

  };

  const tableApiFunction = async(datasetId, datasetName, tableName, dataSourceName, tableId) =>{
      const request ={
        resource: 'api/datasets/fields'
      }

    const TableData={
      "DatasetName":datasetName,
      "Datasource_Name":dataSourceName,
      "table_name":tableName,
      "tableId": parseInt(tableId),
      "type":"table"
    }
    
    if(!textInput[datasetId]?.includes(tableName)){    

      dispatch({type:'PreviewData',payload: []})   
      dispatch({ type: "ErrorQuery", payload: {} });
      dispatch({type:'TableDataLoadingResponse',payload:true}) 

      const TableResponse = await api.dataset.getDatasourceTables(request,TableData)

      if(TableResponse){

      dispatch({type:'TableDataLoadingResponse',payload:false}) 
      dispatch({type:'add table data',payload: handleTableResponse(TableResponse.data.data,datasetId, tableName)}) 
      setTextInput(prevState => ({
        ...prevState,
        [datasetId]: [
          ...(prevState?.[datasetId] || []),
          tableName
        ]
      }));  

    }else{
      dispatch({type:'TableDataLoadingResponse',payload:false}) 
    }
      
    }else{
      toastr.error('error', 'Table already added');
    }
    setDragContent(null);

  }

  const handleTableResponse = (tableResponseData, datasetId, tableName) => {
    return {
      ...tableData,
      [datasetId]: {
        ...(tableData?.[datasetId] || {}),
        [tableName]: [...(tableData?.[datasetId]?.[tableName] || []),
          ...tableResponseData,
        ],
      },
    };
  };


  // Drag over component
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Remove table Name From list from selected table name
  const handleRemove = (id,tableName) => {

// Make a copy of the textInput state using the spread operator
const newTextInput = {...textInput};

// Perform the action using the temp variable
newTextInput[id] = (newTextInput[id] || []).filter(name => name !== tableName);

// Update the state with the modified copy
setTextInput(newTextInput);


    handleSortKeyValueRemove(id, tableName)

    // SetChecked also handled in handleDataSetRemove function
    
    dispatch({type:'add table data',payload: handleDataSetRemove(id, tableName)}) 
    dispatch({type:'SortValue',payload: handleSortValuesRemove(id, tableName)}) 
    dispatch({type:'SortKeyValue',payload: handleSortKeyValueRemove(id, tableName)})
    dispatch({ type: "ErrorQuery", payload: {} });
    dispatch({type:'PreviewData',payload: []}) 
    dispatch({type:'ActiveTab',payload: 'Visual'}) 
    dispatch({ type: "Filters", payload: [] });




    if(newTextInput[id].length==0){
      delete newTextInput[id]
    }


    
  };


  const handleDataSetRemove = (datasetId, tableName) => {
    const updatedTextInput = { ...tableData };
  
    // Check if datasetId exists in textInput
    if (updatedTextInput[datasetId]) {
      // Check if tableName exists in datasetId
      if (updatedTextInput[datasetId][tableName]) {
        // Delete tableName from datasetId
        delete updatedTextInput[datasetId][tableName];
        delete checked[datasetId][tableName];
        
        // Check if datasetId has no other tables
        if (Object.keys(updatedTextInput[datasetId]).length === 0) {

          // Remove datasetId if it has no tables
          delete checked[datasetId];
          delete updatedTextInput[datasetId];

          
        }
      }
    }  
    return updatedTextInput;
  };

  const handleSortValuesRemove =(datasetId,tableName)=>{

    if(Object.keys(sortValues).length!=0){

    const value = datasetId.concat(".", tableName)
   const filteredValue= sortValues.filter((sortValue)=>!sortValue.value.includes(value));
   return filteredValue;
    } else{ 
      return [];
    }

  }
  
  const handleSortKeyValueRemove = (datasetId, tableName)=>{

    if(Object.keys(sortKeyvalue).length!=0){
    const newObj = { ...sortKeyvalue };
    newObj[datasetId] = newObj[datasetId].filter(({ col }) => !col.startsWith(`${tableName}.`));
    if(newObj[datasetId].length === 0){
      delete newObj[datasetId];
    }

    return newObj;
  }else{
    return [];
  }
  }

  const handleNameChange=(e)=>{
    dispatch({type:'QueryName',payload:e.target.value}) 
  }

  return (
    <Container  style={{ maxWidth: '100%' }}>
      <Row className="my-3">
        <Col xl={6} md={6} sm={6} xxl={6}>
      <input type="text" class="form-control" id="queryName"  placeholder="Enter QueryName" onChange={handleNameChange}/>
      </Col>
      </Row>
      <Container style={{ maxWidth: '100%' }}>
      <Row >
        <Col xl={3} md={3} sm={3} xxl={3} style={{zIndex:"10"}}>
          <ListTable
            handleDragStart={handleDragStart}
            handleDrop={handleDrop}
            handleDragOver={handleDragOver}
            textInput={textInput}
            setTextInput={setTextInput}
            datasets={datasets}
            tableApiFunction={tableApiFunction}
            handleRemove={handleRemove}
          />
        </Col>

        <Col xl={9} md={9} sm={9} xxl={9} className="tableContent">
          <TableComponent
            handleDragStart={handleDragStart}
            handleDrop={handleDrop}
            handleDragOver={handleDragOver}
            textInput={textInput}
            setTextInput={setTextInput}
            handleRemove={handleRemove}
            checked={checked}
            setChecked={setChecked}
            datasets={datasets}
          />
        </Col>
      </Row>
      <Row className="">
        <QueryBuilderFooter screen="addQuery"/>
      </Row>
      </Container>
    </Container>
  );
}

export default QueryBuilder;

