import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './main.css';

function Main({ children, showContent }) {
    return (
        <div
            className="main"
            style={{
                height: '93vh',
                paddingLeft: showContent ? '150px' : '',
                maxHeight: '93vh',
                overflow: 'scroll',
            }}
        >
            <Container style={{ maxWidth: '90%' }}>
                <Row>
                    <Col>{children}</Col>
                </Row>
            </Container>
        </div>
    );
}

export default Main;
