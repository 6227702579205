import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function TablesModal(props) {
    const handleClick = () => {
        props.onHide();
        // props.action(props.id);
    };
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header
                closeButton
                closeVariant="dark"
                className="bg-light text-dark fw-bold fs-5"
            >
                {props.heading}
            </Modal.Header>
            <Modal.Body>
                {/* {props.body} */}
                <div style={{ "max-height": "12em", "overflow-y": "auto", "overflow-x": "auto" }}>
                    {props.body.map((col, index) => {
                        return (
                            <>
                                <div
                                    className="rounded-1 border border-light px-2 py-2"
                                    style={{ backgroundColor: 'rgb(228 228 228)' }}
                                >
                                    {col}
                                </div>
                            </>
                        );
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        handleClick();
                    }}
                >
                    {props.close}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
