export const RouteKeys = {
    Login: '/auth/login',
    Home: '/',
    NotFound: '/page-not-found',
    Auth: '/auth',
    AzureLogin: '/auth/aad-redirect',
    AddUser: '/users',
    CreateUser: '/users/addUser',
    EditUser: '/edituser/:id',
    Query: '/query',
    DataSource: '/dataSource',
    Dataset: '/dataset',
    AddDataset: '/dataset/addDataset',
    EditDataset: '/editDataset/:id',
    AddVariable: '/dataSource/addSource',
    ViewDataSource: '/datasource/:secret',
    UserInfo: '/userinfo',
    AddUsers: '/serviceuser/addUsers',
    EditUsers: '/editUsers',
    QueryBuilder: '/query/querybuilder',
    ViewQuery: '/viewQuery/:id',
    EditQuery: '/editQuery/:id',
    Serviceuser:'/serviceuser',
    processFlow: '/processFlow',
  processTracker: '/processFlow/:item',
  Process:'/process',
};
