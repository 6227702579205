import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Userslist from '../userlist/userslist';
import { Col, Container } from 'react-bootstrap';
import SearchBar from '../../components/searchBar/searchBar';
import TableSkeletonLoader from '../../components/loader/table-skeleton-loader/table-skeleton-loader';
import { IoPersonAddSharp } from 'react-icons/io5';
// import { get_user } from '../../api/apiDomain';
import api from "../../api";

function Userinfo() {
    const authState = useSelector((state) => state.authUser);

    const [userlist, setUserList] = useState([]);
    const [deleted, setDeleted] = useState(false);
    const [filteredusers, setFilteredUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [result, setResult] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

     const navigate = useNavigate();

     const handleSearch = (value) => {
        
        setSearch(value);
    };

    const searchData = async (val) => {
        
        const request = {
            resource: `api/webserviceusers/`,
            search:val
        };

        const response = await api.dataset.getDataset(
            request,
           
        );
        setFilteredUsers(response?.data?.data?.users?.rows)
        setIsLoading(true);
    }
    const searchResult = async () => {
        if (search.trim()) {
            // const searchValue = userlist.filter(val => `${val.first_name} ${val.last_name} `.toLowerCase().includes(search.toLowerCase()))
            const searchValue = await searchData (search)
            if (searchValue?.length) {
            
                setFilteredUsers(searchValue);
            } else {
                setFilteredUsers([]);
                
            }
        } else {
            setFilteredUsers(userlist);
    
        }
    }

    useEffect(() => {
        searchResult()
    }, [search])


    useEffect(async() => {
        try {
            
            const request = {
                resource: "api/webserviceusers/"
            }
            const response = await api.dataset.getDataset(request);

            setUserList(response.data.data.users.rows);
            setFilteredUsers(response.data.data.users.rows)
            setIsLoading(true);
            // axios.get(get_user).then((response) => {
            //         setUserList(response.data.data);
            //         console.log("val", response.data.data)
            //     })
        } catch (e) {
            setIsLoading(false);
            console.error("err", e)
        }

    }, [deleted])

    return (

        <Container>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                <h5 className="text-dark pt-4 fw-bold">Service Users</h5>
                <Col md={4} className="my-1 w-100">
                        <SearchBar
                        
                            handleSearch={handleSearch}
                        ></SearchBar>
                    </Col>
                </div>
                

                {/* <button className="btn"  onClick={() => {
                                navigate('/addUsers');
                            }}>add user</button> */}
                <div className="d-flex gap-2 mt-3 mb-3">
                    {
                        authState?.profile?.role_id === 1 &&
                    <button
                        type="button"
                        className="btn text-light custom_button d-flex"
                        style={{
                            backgroundColor: 'rgb(73, 151, 73)',
                        }}
                        onClick={() => {
                            navigate('/addUsers');
                        }}
                    >
                        <div className="add-user">
                            <div className="icons">
                                <IoPersonAddSharp
                                    size={'15px'}
                                    style={{
                                        marginLeft: '7px',
                                        marginBottom: '4px',
                                    }}
                                />

                                <span>Add User</span>

                            </div>

                        </div>
                    </button>
                    }
                </div>
            </div>
      
        <Userslist data={filteredusers} admin={authState.profile.user_id } setDeleted={setDeleted} deleted={deleted} isLoading={isLoading} />
      

            {/* <Userslist data={userlist} admin={authState.profile.user_id} setDeleted={setDeleted} deleted={deleted} /> */}
        </Container>

    )
}

export default Userinfo;