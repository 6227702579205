import ApiService from './service';
import * as http from './http';
import { stringify } from 'query-string';

export class DatasetService extends ApiService {
    async getDataset(request) {
        const { resource, limit, currentPage, search, from } = request;
        const query = {
            limit,
            page: currentPage,
            search,
            from
        };
        if (!search) {
            delete query.search;
        }
        const url = `${this.apiDomain}/${resource}?${stringify(query)}`;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.error(err));
        return response;
    }
    async getDatasourceTables(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.post(url, data, this.store);
    }
    async getDatasetById(request) {
        const url = `${this.apiDomain}/${request.resource}/${request.id} `;
        const response = await http
            .get(url, this.store)
            .catch((err) => console.error(err));
        return { data: response.data };
    }

    async addDataset(request, data) {
        const url = `${this.apiDomain}/${request.resource}`;
        return http.post(url, data, this.store);
    }

    async updateDataset(request, data) {
        const url = `${this.apiDomain}/${request.resource}/${request.id}`;
        return http.put(url, data, this.store);
    }

    async deleteDataset(request) {
        const url = `${this.apiDomain}/${request.resourse}/${request.id}`;
        let res = await http.remove(url, null, this.store);
        return res?.data;
    }

    async deleteUser(request) {
        // console.log(request);
        const url = `${this.apiDomain}/${request.resource}/${request.id}`;
        return http.patch(url, null, this.store);
    }

    async updateUser(request, data) {
        const url = `${this.apiDomain}/${request.resource}/${request.id}`;
        return http.patch(url, data, this.store);
    }
}
