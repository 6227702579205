import React, { useState, useEffect } from "react";
import api from "../../../api";
import DynamicUserTable from "../../tablewithpaginationandlimit/user-table";
import "./styles.scss";
import { Container, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import TableSkeletonLoader from "../../loader/table-skeleton-loader/table-skeleton-loader";
import { RouteKeys } from "../../../containers/routes/route-keys";
import { toastr } from "react-redux-toastr";
import { FaTrashAlt } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import ModalPage from "../../modal/modal";
import NotFound from "../../../containers/notfound";
import { useSelector } from "react-redux";

export default function DataSource() {
  const [datasource, setDataSource] = useState([]);
  const [singleDeleteId, setSingleDeleteId] = useState();
  const [isSingle, setIsSingle] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteSuccessId, setDeleteSuccessId] = useState('');

  const navigate = useNavigate();
  const authState = useSelector((state) => state.authUser);
  const request = {
    resource: "api/vault",
  };

  const handleDelete = async (secret) => {
    try {
      // if (window.confirm("Are you sure to delete?")) {
      setIsSpinner(true)
      let res = await api.vault.deleteSecret(secret);
      if (res.status !== 200) {
        setIsSpinner(false);
        return toastr.error("Error", res?.message);
      }
      setIsSpinner(false);
      // setDataSource(datasource?.filter((item) => item !== secret));
      toastr.success("Success", "Secret removed");
      setDeleteSuccessId(secret);
      // }
    } catch (error) {
      toastr.error("Error", error.message);
    }
  };

  const defaultColumns = [
    {
      Header: "Connector name",
      Cell: ({ row }) => {
        return <div>{row?.original?.name.connectorName}</div>;
      },
    },
    {
      Header: "Connector",
      Cell: ({ row }) => (
        <div>{row?.original?.name.connector}</div>
      ),
    }
  ];

  const buttonColumns = [{
    Header: "Action",
    Cell: ({ row }) => (
      <>
        <AiFillEye
          className="text-dark mx-2 font-size "
          type="button"
          onClick={() => {
            navigate(`/datasource/${row.original.name.connectorName}`);
          }}
        ></AiFillEye>
        <FaTrashAlt
          type="button"
          className="text-dark mx-2 font-size"
          // onClick={() => handleDelete(row.original.name)}
          onClick={() => {
            setModalShow(true);
            setSingleDeleteId(row.original.name.connectorName);
            setIsSingle(true);
          }}
        ></FaTrashAlt>
      </>
    ),
  }]

  // if (authState?.profile?.role_id === 1) {
  //   columns.push({
  //     Header: "Action",
  //     Cell: ({ row }) => (
  //       <>
  //         <AiFillEye
  //           className="text-dark mx-2 font-size "
  //           type="button"
  //           onClick={() => {
  //             navigate(`/datasource/${row.original.name.connectorName}`);
  //           }}
  //         ></AiFillEye>
  //         <FaTrashAlt
  //           type="button"
  //           className="text-dark mx-2 font-size"
  //           // onClick={() => handleDelete(row.original.name)}
  //           onClick={() => {
  //             setModalShow(true);
  //             setSingleDeleteId(row.original.name);
  //             setIsSingle(true);
  //           }}
  //         ></FaTrashAlt>
  //       </>
  //     ),
  //   })
  // }

  const columns = authState?.profile?.role_id === 1 ? [...defaultColumns, ...buttonColumns] : defaultColumns;

  const fetchDataSourceTable = async () => {
    try {
      setIsLoading(true);
      const datasourceApi = await api.vault.getVaultapi(request);
      // setDataSource(datasourceApi?.data?.data?.keys);
      setDataSource(datasourceApi?.data?.data?.keys);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toastr.error("Error", "Vault Data is not found");
    }
  };

  useEffect(() => {
    fetchDataSourceTable();
  }, [deleteSuccessId]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isSingle && (
        <ModalPage
          show={modalShow}
          onHide={() => setModalShow(false)}
          heading={"Delete Source"}
          body={"Are you sure you want to delete this datasource?"}
          success={"Delete"}
          failure={"Cancel"}
          id={singleDeleteId}
          action={handleDelete}
        />
      )}
      <Container>
        <div className="d-flex justify-content-between">
          {isSpinner && (
            <Spinner
              className="position-absolute opacity-100 top-50 start-50"
              style={{ zIndex: 1 }}
            ></Spinner>
          )}
          <h5 className="text-dark pt-4 fw-bold">Data Source</h5>
          {
            authState?.profile?.role_id === 1 &&
            <div className="d-flex gap-2 mt-3 mb-3">
              <button
                type="button"
                className="btn btn text-light custom_button d-flex flex-row"
                style={{
                  backgroundColor: "rgb(73, 151, 73)",
                }}
                onClick={() => navigate(RouteKeys.AddVariable)}
              >
                <div className="add-user">
                  <div className="icons">
                    <span>Add Source</span>
                  </div>
                </div>
              </button>
            </div>
          }
        </div>
        {isLoading ? (
          <TableSkeletonLoader
            intialrows={2}
            initalcolumns={6}
          ></TableSkeletonLoader>
        ) : (
          <div>
            {
              datasource?.length > 0 ?
                <DynamicUserTable
                  row={
                    datasource?.length
                      ? datasource?.map((item) => ({ name: item }))
                      : []
                  }
                  columns={columns}
                  isPagination={false}
                  isLimit={false}
                ></DynamicUserTable> :
                <NotFound message="No datasource found" />
            }
          </div>
        )}
      </Container>
    </>
  );
}
