import React, { useEffect, useState } from 'react'
import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  useEdgesState,
  useNodesState,
} from 'reactflow'
import 'reactflow/dist/style.css'
import './flowdiagram.css'
import useFlowDiagram from './hooks/useflowdiagram'


function FlowDiagram() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const { data, edgesData } = useFlowDiagram()
  const initialNodes = data.map((item) => ({
    id: item.id,
    position: item.position,
    data: { label: item.label },
    style: {
      ...item.style,
      'box-shadow': 'rgba(100, 100, 111, 0.2) 0px 17px 29px 0px',
    },
  }))

  const edgeOptions = {
    animated: true,
    style: {
      stroke: '#ed1c1c',
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 30,
      height: 30,
      color: '#ed1c1c',
    },
  }

  const initialEdges = edgesData
  const [nodes] = useNodesState(initialNodes)
  const [edges] = useEdgesState(initialEdges)


  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    window.addEventListener('orientationchange', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('orientationchange', handleResize)
    }
  }, [])

  return (
    <>
      {/* <div
        className='m-auto text-xl lg:text-2xl text-blue-800 font-semibold fixed'
        style={{ padding: '15px' }}
      >
        Process Flow
      </div> */}
      <div style={{padding: "15px", fontSize:"bolder"}}>Process Flow</div>
      <div className='Container'>
        <div className='wrapper'>
          <ReactFlow
            key={windowWidth}
            nodes={nodes}
            edges={edges}
            defaultEdgeOptions={edgeOptions}
            panOnScroll
            fitView
          >
            <Controls />
            <Background />
          </ReactFlow>
        </div>
      </div>
    </>
  )
}

export default FlowDiagram
