import React, { useState, useEffect } from 'react';
import './Query.scss';
import api from '../../api';
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaEdit, FaTrashAlt, FaRegEye } from 'react-icons/fa';

import SearchBar from '../../components/searchBar/searchBar';
import { useNavigate } from 'react-router-dom';
import TableSkeletonLoader from '../../components/loader/table-skeleton-loader/table-skeleton-loader';
import Limitdropup from '../../components/limit-drop-up/limit-drop-up';
import Pagination from '../../components/pagination/pagination';
import { IoAddCircle } from 'react-icons/io5';
import QueryConfigurationTable from '../../components/tablewithpaginationandlimit/configuration-table';
import { Link } from 'react-router-dom';
import ModalPage from '../../components/modal/modal';
import { toastr } from 'react-redux-toastr';
import { CiShare1 } from "react-icons/ci";
import ViewUrlModal from '../../components/modal/viewUrlModal';
import NotFound from '../notfound';
import { useSelector } from 'react-redux'

const Query = () => {
    const authState = useSelector((state) => state.authUser);

    const [user, setUser] = useState([]);
    const [filter, setFilter] = useState();
    const [sort] = useState([]);
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [filterData, setFilterData] = useState();
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [emptyFilter, setEmptyFilter] = useState(false);
    const [emptySearchbar, setEmptySearchbar] = useState(false);
    const [bulkDelete, setBulkDelete] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [deleteLoading, setDeleteLoading] = useState(true);
    const [isCopied, setIsCopied] = useState(false);
    const [copyUrl, setCopyUrl] = useState('');
    const [showUrlModal, setShowUrlModal] = useState(false);
    const navigate = useNavigate();

    const request = {
        resource: 'api/queries',
        limit,
        currentPage,
        search,
        sort,
        filter,
    };
    const requestfordelete = {
        resource: 'api/queries/delete',
    };

    // for limit
    const handlingLimit = (limit) => {
        setLimit(limit);
        setCurrentPage(1);
    };

    //for page change
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    //for sort

    //for search
    const handleSearch = (value) => {
        setEmptyFilter(!emptyFilter);
        setFilter({
            dataSource: [],
            checktypes: [],
        });
        setSearch(value);
        setCurrentPage(1);
    };

    //for filter
    const handleFilter = (value) => {
        setEmptySearchbar(!emptySearchbar);
        setSearch('');
        setFilter(value);
        setCurrentPage(1);
    };

    const handleEdit = (row) => {
        navigate(`/edituser/${row.QueryID}`);
    };

    // copy url
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    const handleCopyClick = (url) => {
        // Asynchronously call copyTextToClipboard
        // const copyText = `${process.env.REACT_APP_CORE_API}/${id}`;
        // setCopyUrl(`${process.env.REACT_APP_CORE_API}/${id}`)
        copyTextToClipboard(url)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                // toastr.success(`"${name}" Url copied`)
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const handleCopy = (id) => {
        setCopyUrl(`${process.env.REACT_APP_CORE_API}/${id}`)
        setShowUrlModal(true);
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Click here to view url.
        </Tooltip>
    );

    const columns = [
        {
            Header: 'QueryID',

            Cell: ({ row }) => (
                <div className="d-flex flex-row gap-3 color ">
                    <p className="query-fontcolor" >
                        {row.original.QueryID}
                        {/* <RiFileCopy2Line onClick={()=>handleCopyClick(row.original.QueryID)}/> */}
                    </p>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <span>
                            <CiShare1 style={{ cursor: "pointer" }} onClick={() => handleCopy(row.original.QueryID)} />
                        </span>
                    </OverlayTrigger>
                </div>
            ),
        },
        {
            Header: 'QueryName',

            Cell: ({ row }) => (
                <div className="d-flex flex-row gap-0  color ">
                    <p className="query-fontcolor">{row.original.queryName}</p>
                </div>
            ),
        },

        {
            Header: 'DataSourceName',

            Cell: ({ row }) => (
                <>
                    <div className="d-flex flex-row gap-0">
                        <div className="query-fontcolor">
                            {row.original?.queryrepositories[0]?.dataSourceName}
                        </div>
                    </div>
                </>
            ),
        },

        {
            Header: 'Created At',

            Cell: ({ row }) => (
                <>
                    <div className="d-flex flexx-row gap-0">
                        <div className="query-fontcolor">
                            {new Date(row.original.createdAt).toLocaleString()}
                        </div>
                    </div>
                </>
            ),
        },
        {
            Header: 'Created By',

            Cell: ({ row }) => (
                <>
                    <div className="d-flex flexx-row gap-0">
                        <div className="query-fontcolor">
                            {row.original.user.display_name}
                        </div>
                    </div>
                </>
            ),
        },
    ];
    const nestedColumns = [
        {
            Header: 'Shared to users',
            accessor: 'user_name',
        },
    ];

    const buttonColumns = [
        {
            Header: 'Actions',
            accessor: 'buttonColumn', // This is not used, but required by React Table
            Cell: ({ row }) =>
                authState?.profile?.role_id === 1 ? (
                    <div className="d-flex flex-row">
                        <FaRegEye
                            type="button"
                            className="text-dark mx-2 font-size"
                            onClick={() => {
                                navigate(`/viewQuery/${row.original.QueryID}`);
                            }}
                        ></FaRegEye>
                        <FaEdit
                            className="text-dark mx-2 font-size "
                            type="button"
                            onClick={() => {
                                navigate(`/editQuery/${row.original.QueryID}`);
                            }}
                        ></FaEdit>
                        <FaTrashAlt
                            type="button"
                            className="text-dark mx-1 font-size"
                            onClick={() => {
                                setModalShow(true);
                                setDeleteId(row.original.QueryID);
                            }}
                        ></FaTrashAlt>
                    </div>
                ) : (
                    <div className="d-flex flex-row">
                        <FaRegEye
                            type="button"
                            className="text-dark mx-2 font-size"
                            onClick={() => {
                                navigate(`/viewQuery/${row.original.QueryID}`);
                            }}
                        ></FaRegEye>
                        <FaEdit
                            className="text-dark mx-2 font-size "
                            type="button"
                            onClick={() => {
                                navigate(`/editQuery/${row.original.QueryID}`);
                            }}
                        ></FaEdit>
                    </div>
                )
        }
    ];

    const fetchUserTable = async () => {
        try {
            setIsLoading(true);
            const userData = await api.crud.getList(request);
            if (userData.data.status !== 200) {
                setIsLoading(false);
                console.error(userData?.data.message);
                return toastr.error("Error", "Queries not found")
            }
            setUser(userData?.data?.data?.queries?.rows);
            setPages(userData?.data?.data?.totalPages);
            setCurrentPage(userData?.data?.data?.currentPage);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    };

    const handleDelete = async (id) => {
        try {
            setDeleteLoading(false);
            const deleteRequest = { ...requestfordelete, resourceId: id };
            const response = await api.crud.Delete(deleteRequest);
            if (response.data.status === 200) {
                toastr.success('Success', 'Query removed');
                fetchUserTable();
                setBulkDelete([]);
            }
            setDeleteLoading(true);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchUserTable();
    }, [limit, currentPage, sort, search, filter]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container>
            <>
                <ViewUrlModal
                    show={showUrlModal}
                    onHide={() => setShowUrlModal(false)}
                    copyUrl={copyUrl}
                    isCopied={isCopied}
                    handleCopyClick={handleCopyClick}
                />
                <ModalPage
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    heading={'Delete Confirmation'}
                    body={'Are you sure you want to delete this query?'}
                    success={'Delete'}
                    failure={'Cancel'}
                    id={deleteId}
                    action={handleDelete}
                />
                <div className="d-flex justify-content-between">
                    <h5 className="text-dark pt-4 fw-bold">Query Builder</h5>

                    <div className="d-flex gap-2 mt-3 mb-3">

                        <button
                            type="button"
                            className="btn btn text-light custom_button d-flex flex-row"
                            style={{
                                backgroundColor: '#b30000',
                                borderRadius: '18px',
                            }}
                            onClick={() => {
                                navigate('/query/querybuilder');
                            }}
                        >
                            <div className="add-user">
                                <div className="icons">
                                    <IoAddCircle
                                        size={'18px'}
                                        style={{
                                            marginRight: '7px',
                                            marginBottom: '4px',
                                        }}
                                    />
                                    <span>Create Query Builder</span>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>

                <Row>
                    <Col md={4} className="my-1">
                        {
                            user?.length > 0 &&
                            <SearchBar
                                handleSearch={handleSearch}
                                emptySearchbar={emptySearchbar}
                            ></SearchBar>
                        }
                    </Col>
                    <Col md={8}>
                        {/* <Filters
                            filterData={filterData}
                            handleFilter={handleFilter}
                            emptyFilter={emptyFilter}
                            statusData={['Active', 'Inactive']}
                        ></Filters> */}
                    </Col>
                </Row>

                {isLoading ? (
                    <TableSkeletonLoader
                        intialrows={8}
                        initalcolumns={5}
                    ></TableSkeletonLoader>
                ) : (
                    <>
                        {
                            user?.length > 0 ?
                                <>
                              

                                
                                    <QueryConfigurationTable
                                        row={user}
                                        columns={columns}
                                        nestedColumns={nestedColumns}
                                        buttonColumns={buttonColumns}
                                        isPagination={false}
                                        isLimit={false}
                                        pages={pages}
                                        limit={limit}
                                        fetchQueries={fetchUserTable}
                                    // handlingLimit={handlingLimit}
                                    // handlePageChange={handlePageChange}
                                    // currentPage={currentPage}
                                    ></QueryConfigurationTable>
                                  
                                    <div className="d-flex justify-content-end  w-100 pagination-scrollbar ">
                                        <Row>
                                            <div className="d-flex  mx-3">
                                                <div className="py-2 font-weight-semibold">
                                                    Records per page
                                                </div>
                                                <div className=" px-1">
                                                    <Limitdropup
                                                        limit={limit}
                                                        handlingLimit={handlingLimit}
                                                    ></Limitdropup>
                                                </div>
                                            </div>
                                        </Row>

                                        <div className="py-1">
                                            <Pagination
                                                currentPage={currentPage}
                                                handlePageChange={handlePageChange}
                                                pages={pages}
                                            ></Pagination>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => handleSearch("")}
                                    >
                                        Back
                                    </button>
                                    <NotFound message="No queries found" />
                                </>
                        }
                    </>
                )}
            </>
        </Container>
    );
};

export default Query;
