import React, { useMemo, useState } from 'react';
import NestedTable from '../nestedTable/nestedTable';
import MainTable from '../mainTable/mainTable';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import './table.scss';
import { Row, Col, Button } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { useTable, useExpanded } from 'react-table';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import ButtonTable from '../buttonTable/buttonTable';
import api from '../../../../api';
import fetchUserTable from "../../../../containers/Queries/Query"
export default function Tablefunc({
    columns,
    row,
    nestedColumns,
    buttonColumns,
    fetchQueries,
    setBulkDelete,
    bulkDelete,
}) {
    const [arrow, setArrow] = useState(-1);
    const memoziedColumns = useMemo(() => columns, [columns]);
    const memoziedRow = useMemo(() => row, [row]);
    const request = {
        resource: 'api/queryusers',

    };
    function CustomToggle({ children, eventKey, id }) {
        const decoratedOnClick = useAccordionButton(eventKey, () => {
            arrow === eventKey ? setArrow(-1) : setArrow(eventKey);
        });

        return (
            <>
                <Button
                    className="bg-transparent text-dark border-0 p-0 m-0"
                    type="button"
                    onClick={() => { decoratedOnClick() }}
                >
                    {children}
                </Button>
            </>
        );
    }
    const tableInstance = useTable(
        {
            columns: memoziedColumns,
            data: memoziedRow,
            initialState: {
                pageIndex: 0,
                hiddenColumns: ['Data source'], //use property option, in columns define id name "id"
            },
        },
        useExpanded,
    );
    const { getTableProps, rows, prepareRow } = tableInstance;
    return (
        <>
            <Table
                {...getTableProps()}
                responsive
                className="custom-table-height tablefontsize querytable"
            >
                <Accordion>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps()} className="my-0">
                                <Row
                                    className="rounded-3 mx-0 my-0"
                                >
                                    <Col
                                        md={1}
                                        lg={1}
                                        sm={12}
                                        xs={12}
                                        className="d-flex align-items-start mt-2"
                                    >
                                        <Row>
                                            <Col md={1} lg={1} sm={1} xs={1} className='ms-2 me-2'>
                                                <CustomToggle eventKey={i}>
                                                    {row.original.check_type !==
                                                        'schema' ? (
                                                        <>
                                                            {arrow === i ? (
                                                                <FaAngleDown></FaAngleDown>
                                                            ) : (
                                                                <FaAngleRight></FaAngleRight>
                                                            )}
                                                        </>
                                                    ) : null}
                                                </CustomToggle>
                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col>
                                        <Card.Header className="border-0 p-0">
                                            <Row>
                                                <Col
                                                    lg={10}
                                                    md={10}
                                                    sm={10}
                                                    xs={10}
                                                    className="p-0 mx-0"
                                                >
                                                    <MainTable
                                                        data={[row.original]}
                                                        columns={columns}
                                                    ></MainTable>
                                                    <Accordion.Collapse
                                                        eventKey={i}
                                                    >
                                                        <NestedTable
                                                            data={
                                                                window.location.pathname === "/serviceuser" ? row.original?.queryusers.filter(ele => ele.query_store).map(ele => ele.query_store && { QueryID: ele.query_store.QueryID, queryName: ele.query_store.queryName })
                                                                : row.original?.queryusers?.filter((ele) => ele.web_service_user).map(ele => ele.web_service_user && { user_name: ele.web_service_user?.first_name + " " + ele.web_service_user?.last_name, first_name: ele.web_service_user?.first_name, last_name: ele.web_service_user?.last_name, id: ele.web_service_user?.id })
                                                            }
                                                            queryId={row.original.QueryID}
                                                            userId={row.original.id}
                                                            nestedColumns={
                                                                nestedColumns
                                                            }
                                                            checkType={
                                                                row.original
                                                                    .check_type
                                                            }
                                                            fetchQueries={fetchQueries}
                                                        >
                                                        </NestedTable>
                                                    </Accordion.Collapse>
                                                </Col>
                                                <Col
                                                    lg={1}
                                                    md={1}
                                                    sm={1}
                                                    xs={1}
                                                    className="d-flex align-items-start mt-2 p-0 "
                                                >
                                                    <ButtonTable
                                                        data={[row.original]}
                                                        buttonColumns={
                                                            buttonColumns
                                                        }
                                                    ></ButtonTable>
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                    </Col>
                                </Row>
                            </div>
                        );
                    })}
                </Accordion>
            </Table>
        </>
    );
}
