import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import UserTable from '../../components/table/user-tables/user-tables';
import TableSkeletonLoader from '../../components/loader/table-skeleton-loader/table-skeleton-loader';

import PlottyApp from "../../components/plotty/index";

import { toastr } from 'react-redux-toastr';


import api from '../../api';
import { useNavigate, useParams } from 'react-router-dom';

const ViewQuery = () => {
    const [active, setActive] = useState(0);
    const [row, setRow] = useState([]);
    const [column, setColumn] = useState([]);
    const [modifiedPreviewData, setModifiedPreviewData] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const { previewData: queryResult, activeButton, queryName, isLoadingPreview } =
        useSelector((state) => state.queryBuilder);

    useEffect(async () => {
        const request = {
            resource: 'api/queries/view',
            id: params.id,
        };

        dispatch({ type: 'ActiveTab', payload: 'Preview' });
        dispatch({ type: 'PreviewLoading', payload: false });
        const res = await api.dataset.getDatasetById(request);

        if (res.data.status === 200) {

            const queryResult = res?.data?.data?.queryResult || {};
            dispatch({
                type: 'PreviewData',
                payload: res?.data?.data?.queryResult,
            });
            dispatch({ type: 'PreviewLoading', payload: true });
            dispatch({ type: 'ActiveTab', payload: 'Preview' });
            dispatch({ type: 'QueryName', payload: res?.data?.data?.queryName });
            let tempData = []
            Object.keys(queryResult)?.map(result => {
                if (queryResult[result].length > 0) {
                    tempData = [...tempData, ...queryResult[result]]
                }
            })
            console.info({ tempData })
            setModifiedPreviewData(tempData);
            let firstQuery;
            for (let i = 0; i < tempData.length; i++) {
                if (tempData[i] && tempData[i].result && tempData[i].result.length > 0) {
                    firstQuery = tempData[i].result;
                    setActive(i);
                    break;
                }
            }
            console.info({ firstQuery })
            let key;
            if (firstQuery) {
                key = Object.keys(
                    firstQuery[0]
                );
            } else {
                key = []
            }
            const column = [];
            key?.map((k) => {
                column.push({
                    Header: k.split('.')[1],
                    Cell: ({ row }) => {
                        return (
                            <div>
                                {row?.original?.[k.split('.')[1]]}
                            </div>
                        );
                    },
                });
            });
            const value = firstQuery?.map(query => {
                let tempObj = {};
                Object.keys(query).map(pro => {
                    tempObj = { ...tempObj, [pro.split(".")[1]]: query[pro] }
                })
                return tempObj
            })
            setColumn(column);
            setRow(value)
            toastr.success(res?.data?.message);
        } else {
            dispatch({ type: 'PreviewLoading', payload: true });
            toastr.error('Error while getting preview');
        }
    }, []);

    // Button active on not
    const handleButtonClick = (buttonName) => {
        dispatch({ type: 'ActiveTab', payload: buttonName });
    };

    const PreviewComponent = () => {
        return (
            <Row>
                <div className="h4">Result</div>
                <>
                    <ul className="nav nav-tabs mb-4 me-2">
                        {modifiedPreviewData?.map((tablearr, index) => {
                            if (tablearr['result'].length !== 0) {

                                const title = Object.keys(
                                    tablearr['result'][0],
                                )[0].split('.')[0];
                                const key = Object.keys(
                                    tablearr['result'][0],
                                );
                                const column = [];
                                // console.log(key,"key")
                                key?.map((k) => {
                                    column.push({
                                        Header: k.split('.')[1],
                                        Cell: ({ row }) => {
                                            return (
                                                <div>
                                                    {row?.original?.[k.split('.')[1]]}
                                                </div>
                                            );
                                        },
                                    });
                                });
                                const value = tablearr['result'].map(query => {
                                    let tempObj = {};
                                    Object.keys(query).map(pro => {
                                        tempObj = { ...tempObj, [pro.split(".")[1]]: query[pro] }
                                    })
                                    return tempObj
                                });

                                return <li className="nav-item" key={index} onClick={() => {

                                    setActive(index);
                                    setRow(value);
                                    setColumn(column);
                                }}>
                                    <span role="button" className={index === active ? "nav-link active" : "nav-link"} aria-current="page" >{title}</span>
                                </li>
                            }
                        })}
                    </ul>
                </>

                <div>
                    <div className='card'>
                        <div>
                            <div className='card-header'>Visualization Explorer</div>
                            <div className='table-responsive'>

                                <PlottyApp data={row} style={{ "width": "100%" }} />
                            </div>

                        </div>
                    </div>


                    <div style={{ padding: "25px" }}>

                    </div>

                    <div className='card'>
                        <div className='card-header'>Data</div>
                        <div>
                            <UserTable
                                row={row}
                                columns={column}
                                preview="preview"
                            />
                        </div>
                    </div>



                </div>

            </Row>
        );
    };

    return (
        <div>
            {isLoadingPreview ? (
                <div>
                    <Row className="my-3">
                        <Col xl={6}>
                            <input
                                type="text"
                                class="form-control"
                                id="queryName"
                                placeholder="Enter QueryName"
                                // onChange={handleNameChange}
                                value={queryName}
                                disabled
                            />
                        </Col>
                        <Col xl={6} className="text-end">
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    dispatch({ type: 'ResetState' });
                                    navigate('/query');
                                }}
                            >
                                Back
                            </button>
                        </Col>
                    </Row>

                    {
                        <div className="d-flex justify-content-end">
                            <button
                                className={`preview ${activeButton === 'Editor' ? 'active' : ''
                                    }`}
                                onClick={() => handleButtonClick('Editor')}
                            >
                                Editor
                            </button>
                            <button
                                className={`preview ${activeButton === 'Preview' ? 'active' : ''
                                    }`}
                                onClick={() => handleButtonClick('Preview')}
                            >
                                Preview
                            </button>
                        </div>
                    }

                    {activeButton === 'Editor' ? (
                        <div style={{ backgroundColor: 'black' }}>
                            {Object.keys(queryResult).map((dataset) => {
                                return (
                                    <>
                                        {queryResult[dataset]?.map((pre) => {
                                            return (
                                                <div className="textArea">
                                                    {pre.sql}s
                                                </div>
                                            );
                                        })}
                                    </>
                                );
                            })}
                        </div>
                    ) : (
                        <></>
                    )}
                    {activeButton === 'Preview' ? <PreviewComponent /> : <></>}
                </div>
            ) : (
                <TableSkeletonLoader
                    intialrows={10}
                    initalcolumns={1}
                ></TableSkeletonLoader>
            )}
        </div>
    );
}

export default ViewQuery;
