import React from 'react';
import User from '../../containers/user/User';

export default function AddUser() {
  return (
    <div>
      <User></User>
    </div>
  );
}
