const initialState = {
    tableData: [],
    searchDataSet: '',
    datasetResponse: [],
    isLoadingDataset: true,
    isLoadingTableData: false,
    sortValues: [],
    sortKeyvalue: {},
    groupByValues: [],
    ddValues: [],
    queryName: '',
    previewData: [],
    isEditing: false,
    isLoadingPreview: false,
    activeButton: 'Visual',
    filterValues: [],
    errorQuery: {},
    editResponse: [],
};

const ADD_TABLEDATA = 'add table data';

export const queryBuilderReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TABLEDATA:
            return {
                ...state,
                tableData: action.payload,
            };
        case 'SearchDataset':
            return {
                ...state,
                searchDataSet: action.payload,
            };
        case 'FilteredDatasetResponse':
            return {
                ...state,
                datasetResponse: action.payload,
            };

        case 'DatasetLoadingResponse':
            return {
                ...state,
                isLoadingDataset: action.payload,
            };
        case 'TableDataLoadingResponse':
            return {
                ...state,
                isLoadingTableData: action.payload,
            };

        case 'SortValue':
            return {
                ...state,
                sortValues: action.payload,
            };
        case 'SortKeyValue':
            return {
                ...state,
                sortKeyvalue: action.payload,
            };
        case 'GroupByValue':
            return {
                ...state,
                groupByValues: action.payload,
            };
        case 'DDValue':
            return {
                ...state,
                ddValues: action.payload,
            };
        case 'QueryName':
            return {
                ...state,
                queryName: action.payload,
            };
        case 'UpdateQuery':
            return {
                ...state,
                query: action.payload,
            };

        case 'PreviewData':
            return {
                ...state,
                previewData: action.payload,
            };
        case 'IsEditing':
            return {
                ...state,
                isEditing: action.payload,
            };
        case 'PreviewLoading':
            return {
                ...state,
                isLoadingPreview: action.payload,
            };
        case 'ActiveTab':
            return {
                ...state,
                activeButton: action.payload,
            };
        case 'ResetState':
            return initialState;

        case 'Filters':
            return {
                ...state,
                filterValues: action.payload,
            };

        case 'ErrorQuery':
            return {
                ...state,
                errorQuery: action.payload,
            };
        case 'EditResponse':
            return {
                ...state,
                editResponse: action.payload,
            };
        default:
            return state;
    }
};
