import React, { useEffect, useState } from "react";
import TableSkeletonLoader from "../../loader/table-skeleton-loader/table-skeleton-loader";
import SearchBar from "../../searchBar/tableSearchBar";

function ListTable({ checked, setChecked, tablesList, isTableLoading, isLoading, error, setError }) {

  const [tables, setTables] = useState(tablesList);
  const [search, setSearch] = useState("");

  const handleSearch = (value) => {
    setSearch(value);
  };

  const serchResult = () => {
    if(search.trim()) {
      const searchValue = tablesList.filter(val => val.toLowerCase().includes(search.toLowerCase()))
      if(searchValue?.length){
        setTables(searchValue);
      } else {
        setTables([]);
      }
    } else {
      setTables(tablesList);
    }
  }

  // console.log({tables, tablesList, search});

  useEffect(() => {
    if(tablesList?.length > 0){

      setTables(tablesList)
    }
  }, [tablesList])

  useEffect(() => {
    serchResult()
  }, [search])

  return (
    <>
      {
        isTableLoading ? (
          <TableSkeletonLoader
            intialrows={2}
            initalcolumns={1}
          ></TableSkeletonLoader>
        ) :
        tablesList?.length ?
            <>
              <p className="fw-bold">Choose Tables<span className="text-danger">*</span></p>
              <div style={{ padding: "5px", height: "270px" }} className="border border-0 bg-light border-secondary rounded-2">
                <SearchBar
                  handleSearch={handleSearch}
                />
                {
                  tables?.length ?
                <div className="mt-1" style={{ "max-height": "14em", "overflow-y": "auto" }}>
                  {tables.map((col, index) => {
                    return (
                      <>
                        <div
                          className="rounded-1 my-1 px-4 py-2"
                          style={{ backgroundColor: (index % 2 === 0) ? 'rgb(228 228 228)' : 'transparent' }}
                        >
                          <label>
                          <input
                            type="checkbox"
                            name={col}
                            disabled={isLoading}
                            checked={checked?.length && checked?.includes(col) ? true : false}
                            onChange={(e) => {
                              let tempChecked = [...checked];
                              if (!checked.includes(e.target.name)) {
                                // setChecked([...checked, e.target.name])
                                tempChecked = [...tempChecked, e.target.name]
                              } else {
                                // setChecked(checked?.filter(item => item !== e.target.name))
                                tempChecked = tempChecked?.filter(item => item !== e.target.name)
                              }
                              setChecked(tempChecked);
                              if (tempChecked?.length) {
                                setError({ ...error, tables: "" });
                              } else {
                                setError({ ...error, tables: "Please select atleast one table" });
                              }
                            }}
                          />
                          {`  `}{col}
                          </label>
                         
                        </div>
                        {/* <span id="name"
              style={{display:"none"}}>{dataset.DataSetName}</span> */}
                      </>
                    );
                  })}
                </div> : "No tables found"
                }
                <div className="text-danger pt-1">
                  {error.tables ? error.tables : ""}
                </div>
              </div>
            </>
            : ""
      }
    </>
  );
};

export default ListTable;