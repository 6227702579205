import { useNavigate } from 'react-router-dom';
import ToolTip from '../../tooltip';

const useFlowDiagram = () => {
  const navigate = useNavigate()
  const Entity = ({ title, url, urlItem }) => {
    let style = { textDecoration: 'undeline' }
    return (
      <div className='node'>
        <p
          onClick={() => (url ? navigate(url) : null)}
          style={style}
          className="m-0"
        >
          {title}
        </p>
        <ToolTip
          position='above'
          forceCenter={true}
          content={(urlItem || title) + ' info'}
          trigger={['hover', 'touch']}
        >
            <span class="material-symbols-outlined" 
            onClick={() => navigate(`/process`,{state:{data:title}})}
            style={{fontSize:"15px"}}>
            info
            </span>
          {/* <span
            className='icon-info'
            onClick={() => navigate(`/processFlow/${urlItem || title}`)}
          >yuu</span> */}
        </ToolTip>
        
      </div>
    )
  }
  const data = [
    {
      id: '1',
      label: <Entity title={`Data Sharing`} />,
      position: { x: 350, y: 60 },
      style: {
        background: '#d56ff2',
        border: '2px solid #ce02ce',
      },
    },
    {
      id: '2',
      label: <Entity title={`Service Users`} url={'/serviceuser'}/>,
      position: { x: 100, y: 170 },
      style: {
        background: 'rgba(147, 197, 253,1)',
        border: '2px solid blue',
      },
    },
    {
      id: '3',
      label: <Entity title={`Datasource`} url={'/dataSource'}/>,
      position: { x: 270, y: 170 },
      style: {
        background: 'rgba(147, 197, 253,1)',
        border: '2px solid blue',
      },
    },
    {
      id: '4',
      label: <Entity title={`Dataset`} url={'/dataset'}/>,
      position: { x: 540, y: 170 },
      style: {
        background: '#ef6767',
        border: '2px solid red',
      },
    },
    {
      id: '6',
      label: <Entity title={`Users`} url={'/users'}/>,
      position: { x: 450, y: 270 },
      style: {
        background: '#79f287',
        border: '2px solid green',
      },
    },
    {
      id: '9',
      label: <Entity title={`Queries`} url={'/query'}/>,
      position: { x: 710, y: 170 },
      style: {
        background: 'rgba(147, 197, 253,1)',
        border: '2px solid blue',
      },
    },
  ]

  // e1-> edge1
  const edgesData = [
    { id: 'e1', source: '1', target: '2' },
    { id: 'e2', source: '1', target: '3' },
    { id: 'e3', source: '1', target: '4' },
    { id: 'e6', source: '6', target: '7' },
    { id: 'e7', source: '6', target: '8' },
    { id: 'e10', source: '1', target: '6' },
    { id: 'e11', source: '6', target: '5' },
    { id: 'e12', source: '1', target: '9' },
  ]

  return {
    edgesData,
    data,
  }
}

export default useFlowDiagram
