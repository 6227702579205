import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ContainerLoader from '../../components/loader/container-loader';
import AzureLogin from '../auth/AzureLogin';
import FlowDiagram from '../../components/flowdiagram/flowdiagram';
import AuthenticateRoute from './AuthenticateRoute';
import RedirectIfAuthenticatedRoute from './RedirectIfAuthenticatedRoute';
import { RouteKeys } from './route-keys';
import AddUser from '../../components/add-user/addUser';
import QueryBuilder from '../QueryBuilder/QueryBuilder';

import Query from '../Queries/Query';
import DataSource from '../../components/table/data-source/datasource';
import Dataset from '../../components/table/data-set/dataset'
import AddDataset from '../../components/dataSet/AddDataset';
import EditDataset from '../../components/dataSet/EditDataset';
import Userinfo from '../userinfo/user';
import AddUsers from '../adduser/adduser';
import EditUsers from '../edituser/edituser';
import ViewQuery from '../QueryBuilder/ViewQuery';
import Serviceuser from '../serviceuser/serviceuser';
import EditQuery from '../QueryBuilder/EditQuery/EditQuery';
import Process from '../../components/processWrapper/process';

const ViewDataSource = lazy(() =>
    import('../../components/ViewDataSource/viewDataSource'),
);

const AddVariable = lazy(() => import('../../components/KeyPairs/addVariable'));
const NotFound = lazy(() => import('../notfound'));
const AuthPage = lazy(() => import('../auth'));
const CreateUser = lazy(() => import('../../components/Crud/CreateUser'));
const EditUser = lazy(() => import('../../components/Crud/EditUser'));

class NavRoutes extends React.Component {
    render() {
        return (
            <Suspense fallback={<ContainerLoader />}>
                <Routes>
                    <>
                        <Route
                            path={RouteKeys.Home}
                            element={
                                <AuthenticateRoute path={RouteKeys.Home}>
                                    <Query />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.AddUser}
                            element={
                                <AuthenticateRoute path={RouteKeys.AddUser}>
                                    <AddUser />
                                </AuthenticateRoute>
                            }
                        />

                        <Route
                            path={RouteKeys.Auth}
                            element={
                                <RedirectIfAuthenticatedRoute>
                                    <AuthPage />
                                </RedirectIfAuthenticatedRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.AzureLogin}
                            element={
                                <RedirectIfAuthenticatedRoute>
                                    <AzureLogin />
                                </RedirectIfAuthenticatedRoute>
                            }
                        />

                        <Route
                            path={RouteKeys.CreateUser}
                            element={
                                <AuthenticateRoute path={RouteKeys.CreateUser}>
                                    <CreateUser />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.EditUser}
                            element={
                                <AuthenticateRoute path={RouteKeys.EditUser}>
                                    <EditUser />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.Query}
                            element={
                                <AuthenticateRoute path={RouteKeys.Query}>
                                    <Query />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.DataSource}
                            element={
                                <AuthenticateRoute path={RouteKeys.DataSource}>
                                    <DataSource />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.AddDataset}
                            element={
                                <AuthenticateRoute path={RouteKeys.AddDataset}>
                                    <AddDataset />
                                </AuthenticateRoute>
                            }
                        />
                     
                        <Route
                            path={RouteKeys.Dataset}
                            element={
                                <AuthenticateRoute path={RouteKeys.Dataset}>
                                    <Dataset />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.Process}
                            element={
                                <AuthenticateRoute path={RouteKeys.Process}>
                                    <Process />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.EditDataset}
                            element={
                                <AuthenticateRoute path={RouteKeys.EditDataset}>
                                    <EditDataset />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.ViewDataSource}
                            element={
                                <AuthenticateRoute path={RouteKeys.ViewDataSource}>
                                    <ViewDataSource />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.AddVariable}
                            element={
                                <AuthenticateRoute path={RouteKeys.AddVariable}>
                                    <AddVariable />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.UserInfo}
                            element={
                                <AuthenticateRoute path={RouteKeys.UserInfo}>
                                    <Userinfo />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.AddUsers}
                            element={
                                <AuthenticateRoute path={RouteKeys.AddUsers}>
                                    <AddUsers />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.EditUsers}
                            element={
                                <AuthenticateRoute path={RouteKeys.EditUsers}>
                                    <EditUsers />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.QueryBuilder}
                            element={
                                <AuthenticateRoute path={RouteKeys.QueryBuilder}>
                                    <QueryBuilder />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.ViewQuery}
                            element={
                                <AuthenticateRoute path={RouteKeys.ViewQuery}>
                                    <ViewQuery />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.Serviceuser}
                            element={
                                <AuthenticateRoute>
                                    <Serviceuser />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.processFlow}
                            element={
                                <AuthenticateRoute>
                                    <FlowDiagram />
                                </AuthenticateRoute>
                            }
                        />
                        <Route
                            path={RouteKeys.EditQuery}
                            element={
                                <AuthenticateRoute path={RouteKeys.EditQuery}>
                                    <EditQuery />
                                </AuthenticateRoute>
                            }
                        />
                        <Route path={RouteKeys.NotFound} element={
                            <AuthenticateRoute>
                                <NotFound />
                            </AuthenticateRoute>
                        }
                        />
                        <Route path="*" element={
                            <AuthenticateRoute>
                                <NotFound />
                            </AuthenticateRoute>
                        }
                        />
                    </>
                </Routes>
            </Suspense>
        );
    }
}

export default NavRoutes;
