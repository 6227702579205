import React, { useState, useEffect } from 'react';
import '../userinfo/user.css';
import moment from 'moment';
import api from '../../api';
import { useSelector } from 'react-redux';
import { useNavigate,useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';

export default function AddUsers() {
    const navigate = useNavigate();

    const {state} = useLocation();
    // console.log("val ",state)
    const authState = useSelector((state) => state.authUser);

    const [flag, setFlag] = useState(false);
    const [flag2, setFlag2] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({
        created_by: authState.profile.user_id,
    });
    const [validateemail, setValidateemail] = useState('');
    let [secretkey, setSecretkey] = useState('');
    let [accesskey, setAccesskey] = useState('');
    const [error,setError]=useState("");

    const beitems = [
        {
            label: 'Email',
            name: 'email'
        },
        {
            label: 'First Name',
            name: 'first_name'
        },
        {
            label: 'Last Name',
            name: 'last_name'
        }
        
    ];
    const afitems = ['Secret Key'];

    const beitems1 = [

        {
            Name: 'Email',
            key: 'email',
            value: '',
        },
        {
            Name: 'First Name',
            key: 'first_name',
            value: '',
        },
        {
            Name: 'Last Name',
            key: 'last_name',
            value: '',
        }
    ];

    const onSubmit = async () => {
        setLoading(true);

        const request = {
            resource: 'api/webserviceusers/create',
        };

        const payloadDetails = {
            first_name: userDetails.first_name,
            last_name: userDetails.last_name,
            email: userDetails.email,
            validity: userDetails.validity,
        };
        const response = await api.dataset.getDatasourceTables(
            request,
            payloadDetails,
        );

        // console.log({ response });
        if (response.status == 200) {
            const bytes = CryptoJS.AES.decrypt(response.data.data.secret_key, process.env.REACT_APP_CRYPTO_SECRET);
            const decryptAccessToken = bytes.toString(CryptoJS.enc.Utf8);
            setSecretkey(decryptAccessToken);
            setAccesskey(response.data.data.access_key);
            setLoading(false);
        } else {
            setLoading(true);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setFlag(true);
        setFlag2(true);
        // console.log(e);

        const formData = {};
        localStorage.setItem('mytime', Date.now());
        beitems.forEach((item) => {
            formData[item] = document.getElementById(item)?.value;
        });
        afitems.forEach((item) => {
            formData[item] = document.getElementById(item)?.value;
        });

        localStorage.setItem('formData', JSON.stringify(userDetails));
        // console.log(JSON.stringify(formData));
    };

    function ValidateEmail(mail) {
        if (
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                userDetails.email,
            )
        ) {
            const temp_mail=state.data.filter((data)=>data.email===userDetails.email)
            if(temp_mail.length !==0){
                // console.log("check email",temp_mail)
                setValidateemail('false');
                setError("Email already exist");
                return false;
               
                
            }else{
                setValidateemail('true');
                setError("");
                return true;
            }
            
        }else{
        // console.log('You have entered an invalid email address!');
        setValidateemail('false');
        setError("Enter valid email*");
    
        return false;
        }
    }

    var date = new Date();

    React.useEffect(() => {
        const formData = localStorage.getItem('formData');
        if (formData) {
            const parsedData = JSON.parse(formData);

            date.setDate(date.getDate() + userDetails.validity);
        }
    }, []);

    // let vi = date?.toISOString()?.split('T')[0];
    // console.log(date.toISOString().split('T')[0]);

    const handleChange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });

        // handleError();
    };

    // console.log(userDetails.validity);

    // console.log(date);

    return (
        <div className="cntr ms-5 ">
            {loading && (
                <div
                    class="d-flex justify-content-center mt-5"
                    style={{ marginTop: '300px', width: '800px' }}
                >
                    <div class="spinner-border" role="status">
                        {/* <span class="sr-only">Loading...</span> */}
                    </div>
                </div>
            )}
            {!loading && (
                <div
                    class="container rounded-5 mx-2 my-4 p-4 w-75 position-relative m-auto"
                    style={{
                        boxShadow: 'rgba(0,0,0,0.2) 0px 2px 8px 0px',
                        backgroundColor: 'whitesmoke',
                    }}
                >
                    <h4 className="fw-bold ms-4 text-center m-auto">
                        Add User
                    </h4>
                    <div class="row">
                        
                            <div>
                                <form onSubmit={handleSubmit}>
                                    {/* {beitems.map((data, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className="form-group"
                                            >
                                                <div className="p-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                        {data.label}
                                                    </label>
                                                    <input
                                                        className="form-control "
                                                        id="exampleInputEmail1"
                                                        placeholder={
                                                            data.placeholder
                                                        }
                                                        name={data.name}
                                                        disabled={flag}
                                                        value={
                                                            userDetails[
                                                                data.name
                                                            ] || ''
                                                        }
                                                        onChange={(e) => {
                                                            // handleChange(e);
                                                            if (
                                                                data.name ==
                                                                'email'
                                                            ) {
                                                                handleChange(e);
                                                                ValidateEmail();
                                                            } else {
                                                                handleChange(e);
                                                            }

                                                            // setErrData({ ...errorData, errName:""});
                                                        }}
                                                    />
                                                </div>

                                                {data.name == 'email' &&
                                                validateemail == 'false' &&
                                                data != '' ? (
                                                    <div className="text-danger">
                                                        Enter valid email*
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        );
                                    })} */}
                                    <div
                                                className="form-group "
                                            >
                                                <div className="p-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                       Email
                                                    </label>
                                                    <input
                                                   
                                                        className="form-control "
                                                        id="exampleInputEmail1"
                                                        name="email"
                                                        value={
                                                            userDetails.email || ''
                                                        }
                                                        onChange={(e) => {
                                                          
                                                                handleChange(e);
                                                                ValidateEmail();
                                                          

                                                            // setErrData({ ...errorData, errName:""});
                                                        }}
                                                        onBlur={(e) => {
                                                          if (
                                                              e.target.value != ""
                                                          )
                                                          {
                                                            handleChange(e);
                                                            ValidateEmail();
                                                          }
                                                            
                                                      

                                                        // setErrData({ ...errorData, errName:""});
                                                    }}
                                                    />
                                                </div>

                                                { 
                                                validateemail == 'false'
                                                ? (
                                                    <div className="text-danger">
                                                        {error}
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            
                                            <div className="d-flex">
                                            <div
                                                className="form-group w-100"
                                            >
                                                <div className="p-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                       First Name
                                                    </label>
                                                    <input
                                                        className="form-control "
                                                        id="exampleInputEmail1"
                                                        name="first_name"
                                                        value={
                                                            userDetails.first_name || ''
                                                        }
                                                        onChange={(e) => {
                                                            
                                                                handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group w-100" >
                                                <div className="p-2">
                                                    <label htmlFor="exampleInputEmail1">
                                                       Last Name
                                                    </label>
                                                    <input
                                                        className="form-control "
                                                        id="exampleInputEmail1"
                                                        name="last_name"
                                                        value={
                                                            userDetails.last_name || ''
                                                        }
                                                        onChange={(e) => {
                                                            
                                                                handleChange(e);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            </div>
                                    <div className="p-2" style={{marginRight:"50%"}}>
                                        <label id="demo-simple-select-label">
                                            Validity
                                        </label>
                                        <select
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            name="validity"
                                            value={
                                                userDetails['validity'] || ''
                                            }
                                            disabled={flag}
                                            className="form-control mb-2"
                                        >
                                            <option value={''}>
                                                Select validity
                                            </option>
                                            <option
                                                value={moment()
                                                    .add(30, 'days')
                                                    .format('yyyy-MM-DD')}
                                            >
                                                30 Days
                                            </option>
                                            <option
                                                value={moment()
                                                    .add(60, 'days')
                                                    .format('yyyy-MM-DD')}
                                            >
                                                60 Days
                                            </option>
                                            <option
                                                value={moment()
                                                    .add(90, 'days')
                                                    .format('yyyy-MM-DD')}
                                            >
                                                90 Days
                                            </option>
                                            <option
                                                value={moment()
                                                    .add(120, 'days')
                                                    .format('yyyy-MM-DD')}
                                            >
                                                120 Days
                                            </option>
                                            <option
                                                value={moment()
                                                    .add(365, 'days')
                                                    .format('yyyy-MM-DD')}
                                            >
                                                365 Days
                                            </option>
                                        </select>
                    
                                    </div>
                                  
                                </form>
                                <div style={{marginTop:"-7%", marginRight:"1%"}}>
                                <div className="d-flex justify-content-end">
                                {!flag && (
                                <button
                                    type="submit"
                                    className="btn btn-light "
                                    onClick={() => {
                                        navigate('/serviceuser');
                                    }}
                                >
                                    Back
                                </button>
                                )}
                                    {!flag && (
                                        <button
                                            type="button"
                                            className="btn"
                                            style={{
                                                backgroundColor: 'rgb(73, 151, 73)',
                                                color:"white"
                                            }}
                                            disabled={validateemail=="false" || userDetails.email==undefined}
                                            onClick={(e) => {
                                                setFlag(true);
                                                handleSubmit(e);
                                                setFlag2(true);
                                                setLoading(true);
                                                onSubmit();
                                            }}
                                        >
                                            Save
                                        </button>
                                    )}
                                    
                                </div>
                            </div>
                        </div>
                            {flag && (
                                <div style={{paddingTop:"5%"}}>
                                <form className="mt-4">
                                    <div class="main-content">
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">
                                                Access Key
                                            </label>
                                            <input
                                                className="form-control"
                                                id="exampleInputEmail1"
                                                disabled
                                                value={accesskey}
                                            />
                                            <label htmlFor="exampleInputEmail1">
                                                Password
                                            </label>
                                            <input
                                                className="form-control"
                                                id="exampleInputEmail1"
                                                disabled
                                                value={secretkey}
                                            />
                                        </div>

                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            label="Start date"
                                            onChange={(e) => {
                                                console.log(e.$d);
                                                setChangedate(e.$d);
                                            }
                                            }
                                            defaultValue={dayjs(userDetails.validity)}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider> */}
                                    </div>
                                </form>
                                </div>
                            )}

                            {flag2 && (
                                <button
                                    type="submit"
                                    className="btn mt-3"
                                    style={{
                                        backgroundColor: 'rgb(73, 151, 73)',
                                        color:"white"
                                    }}
                                    onClick={() => {
                                        navigate('/serviceuser');
                                    }}
                                >
                                    Return to home
                                </button>
                            )}
                        
                    </div>
                </div>
            )}
        </div>
    );
}
