import React from "react";

//React Bootstrap
import { Accordion, Form } from 'react-bootstrap';

//Redux hooks
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

//Icons
import { IoMdAdd } from 'react-icons/io';
import { IoMdRemove } from 'react-icons/io';

import TableSkeletonLoader from '../../../components/loader/table-skeleton-loader/table-skeleton-loader';

// css
import "../queryBuilder.scss"

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const ListTable = ({ handleDragStart, datasets, textInput, handleRemove,tableApiFunction }) => {



  const dispatch = useDispatch();
  const {datasetResponse,searchDataSet,isLoadingDataset, isLoadingTableData, isLoadingPreview} = useSelector((state) => state.queryBuilder)
  
  const handleInputChange = (event) =>{
    dispatch({type:"SearchDataset", payload:event.target.value})
    handleSearch(event.target.value);
  }

  const renderTooltip = (text) => (
    <Tooltip id="tooltip">
      {text}
    </Tooltip>
  );

  const handleSearch = (value)=>{

    if(searchDataSet.trim()!==""){
    const filterdataSet = datasets.filter((dataset)=>dataset.DatasetName.toLowerCase().includes(value.toLowerCase()))
    dispatch({type:"FilteredDatasetResponse", payload:filterdataSet})

    }else{
    dispatch({type:"FilteredDatasetResponse", payload:datasets})

    }

  }

    return (
      <>
      {isLoadingDataset ?
      <div style={{ padding: "5px",height:"75vh", overflowY:"auto" }} className="border border-0 bg-light border-secondary rounded-2">
    <div className="h5">Data Explorer</div>
    <div className="fw-lighter" style={{fontSize:"13px"}}>Drag & Drop Field Using Visual Builder</div>

    
    <Form.Control 
    className='my-3 py-2' 
    type="text" 
    placeholder="Quick Search" 
    value={searchDataSet}
    onChange={handleInputChange}
    style={{borderTop: 'none', borderLeft: 'none', borderRight: 'none', borderRadius: '0px', borderBottom: '1px solid black'}} 
    />
        <div>
        <Accordion defaultActiveKey="0">
{datasetResponse?.length > 0 ?
<>
{datasetResponse?.map((dataset, index)=>{
  const Tables = dataset.setentities;
     return( <Accordion.Item eventKey={index.toString()}>
      <Accordion.Header>{dataset.DatasetName}</Accordion.Header>
      <Accordion.Body>
      <div>
          {Tables?.map((col) => {
            return (
              <div className="d-flex justify-content-between my-2 " 
              style={{backgroundColor: 'rgb(228 228 228)'}}
              onDragStart={handleDragStart}
              data-table-name={col.entityName}
              data-dataset-name={dataset.DatasetName}
              data-dataset-id={dataset.id}
              data-datasource-name={dataset.Datasource_Name}
              data-table-id={col.id}
              draggable={!isLoadingTableData || !isLoadingPreview}
              >
              <div
                className="rounded-1 px-2 py-2"
                style={{backgroundColor: 'rgb(228 228 228)'}}

              >
                    <OverlayTrigger
      placement="top"
      overlay={renderTooltip(col.entityName)}
      rootClose
      trigger={['hover', 'focus']}
    >
      <span>{col.entityName.length > 19 ? `${col.entityName.slice(0, 19)}...` : col.entityName}</span>
    </OverlayTrigger>
                {/* {col.entityName}  */}
              </div>
              <div className="d-flex align-items-center mx-1 h5">
              { Object.keys(textInput).length!==0 && textInput?.[dataset.id]?.includes(col.entityName) ?<button className="my-button" disabled={isLoadingTableData || isLoadingPreview}><IoMdRemove style={{cursor:isLoadingTableData || isLoadingPreview?"not-allowed":"pointer"}} onClick={()=>{handleRemove(dataset.id,col.entityName)}} /></button> :
              <button className="my-button" disabled={isLoadingTableData || isLoadingPreview}>
                <IoMdAdd onClick={()=>{
                        dispatch({type:'PreviewData',payload: []})   
                        dispatch({ type: "ErrorQuery", payload: {} });
                        dispatch({ type: "IsEditing", payload:false });

                tableApiFunction(dataset.id,dataset.DatasetName,col.entityName,dataset.Datasource_Name, col.id)
                dispatch({type:'ActiveTab',payload: 'Visual'}) 
            }} 
              style={{cursor:isLoadingTableData || isLoadingPreview?"not-allowed":"pointer"}}/> </button> }
              </div>
              
              </div>
            );
          })}
        </div>
      </Accordion.Body>
    </Accordion.Item>)

})} </>:<>No Dataset Found</>}


    </Accordion>

        </div>
      </div> :    <TableSkeletonLoader
                        intialrows={10}
                        initalcolumns={1}
                    ></TableSkeletonLoader>
}
      </>
    );
  };

  export default ListTable;