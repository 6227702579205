import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import '../queryBuilder.scss';
import TableSkeletonLoader from "../../../components/loader/table-skeleton-loader/table-skeleton-loader";
import { useDispatch } from "react-redux";
import "../queryBuilder.scss"
import UserTable from "../../../components/table/user-tables/user-tables";

// icons from react-icon
import { IoMdRemove } from 'react-icons/io';
import { IoMdAdd } from 'react-icons/io';



const customStyles = {
  valueContainer: (base) => ({
    ...base,
    maxHeight:70,
    overflowY:"auto"
  }),
  multiValue:(base, state)=>{
    return state.data.isFixed ? { ...base , backgroundColor :"gray"} :base;
  },
  multiValueLabel:(base, state)=>{
    return state.data.isFixed?
    {...base, fontWeight: "bold", color:"white", paddingRight: 6} : base;
  },
  multiValueRemove:(base, state)=>{
    return state.data.isFixed ? { ...base, display:"none"} :base
  }

};

const OperatorOptions=[
  {label:"IsEqual", value:"iseql"},
  {label:"IsNotEqual", value:"isneql"},
  {label:"Like", value:"like"},
  {label:"StartsWith", value:"startwith"},
  {label:"EndsWith", value:"endwith"},
  {label:"IsNull", value:"isnull"},
  {label:"IsNotNull", value:"isnotnull"},
  {label:"IsLesserThan", value:"ltn"},
  {label:"IsLesserThan or Equals", value:"ltneq"},
  {label:"IsGreaterThan", value:"gtn"},
  {label:"IsGreaterThan or Equals", value:"gtneq"},
];  

const TableComponent = ({
  handleDragOver,
  handleDrop,
  textInput,
  handleRemove,
  checked,
  setChecked,
  
}) => {
  const dispatch = useDispatch();



  const [selectedColumn, setSelectedColumn] = useState();
  const [ddSortValue, setDDSortValue] = useState([]);
  const [ddFilterValues, setDDFiltervalues] = useState([]);
  const [filters, setFilters] = useState([
    { colName: '', Operator: '', Value: '', sequence: 1, condition:'', dataType: ''}
  ]);
  const [showFilter,setShowFilter] = useState(false);

  const {
    tableData,
    isLoadingDataset,
    isLoadingTableData,
    sortKeyvalue,
    sortValues,
    previewData,
    isLoadingPreview,
    isEditing,
    activeButton,
    filterValues,
    errorQuery,
    editResponse
  } = useSelector((state) => state.queryBuilder);


  useEffect(() => {
    const initialDatasets = {};

    if(!isEditing){
    Object.keys(tableData)?.map((datasetId) => {
      if (!checked[datasetId]) {
        initialDatasets[datasetId] = {};
        checked[datasetId] = initialDatasets[datasetId];
      }
      Object.keys(tableData[datasetId]).forEach((tableName) => {
        if (!checked[datasetId][tableName]) {
          const checkedColumns = {};
          tableData[datasetId][tableName].map((columnName) => {
            checkedColumns[columnName.columnName] = true;
          });
          checked[datasetId][tableName] = checkedColumns;
        }
      });
    });
    setChecked({ ...checked });

  }else{
    
    Object.keys(tableData)?.map((datasetId) => {
      if (!checked[datasetId]) {
        initialDatasets[datasetId] = {};
        checked[datasetId] = initialDatasets[datasetId];
      }
      Object.keys(tableData[datasetId]).forEach((tableName) => {
        const modifiedName = tableName.includes(".csv") ? tableName.split(".")[0] : tableName;
        const matchingTable= editResponse.filter((val)=>val.table===modifiedName);
        if (!checked[datasetId][tableName]) {
          const checkedColumns = {};
          tableData[datasetId][tableName].map((cName) => {

            const colName= tableName.concat(".",cName.columnName);
            if(matchingTable[0]?.columns && matchingTable[0]?.columns?.includes(colName)){              
              checkedColumns[cName.columnName] = true;
            }else{
              checkedColumns[cName.columnName] = false;

            }
          });
          checked[datasetId][tableName] = checkedColumns;
        }
      });
    });
    setChecked({ ...checked });

  }

    // setChecked({ ...checked });

    if(Object.keys(filterValues).length === 0){
    
    
      dispatch({ type: "Filters", payload: [{ colName: '', Operator: '', Value: '', sequence: 1, condition:'', dataType: ''}] });
      setFilters([
        { colName: '', Operator: '', Value: '', sequence: 1, condition:'', dataType: ''}
      ])
    }
  }, [textInput]);

  useEffect(() => {
    if(Object.keys(tableData).length !==0){
    getCheckedColumns();
    handleDDvalues();
    }
  }, [checked, textInput]);

  useEffect(() => {
    setShowFilter(true)
    if(!isEditing){
    dispatch({ type: "ErrorQuery", payload: {} });
    dispatch({type:'PreviewData',payload: []}) 
    }
  }, [filterValues, sortValues])


  const handleCheck = (tableName, colName, dataset) => {
    setChecked((prevState) => {
      const prevTable = prevState[dataset]?.[tableName] || {};
      const newTable = { ...prevTable, [colName]: !prevTable[colName] };
      const newDataset = { ...prevState[dataset], [tableName]: newTable };
      return { ...prevState, [dataset]: newDataset };
    });
  };

  const handleDDvalues = () => {
    const ddOption = [];
    const ddsortOption=[]
    const groupByValue=[]
    const ddFilterValues=[]

    // if(!isEditing){
    Object.keys(checked).map((dataset) => {
      Object.keys(checked[dataset])?.map((table) => {
        Object.keys(checked[dataset][table])?.map((col) => {
          const labelData = table.concat(".", col);
          const valueData = dataset.concat(".", table.concat(".", col));
          ddsortOption.push({ value: valueData, label: labelData.concat(".","Asc") })
          ddsortOption.push({ value: valueData, label: labelData.concat(".","Dsc") })

          const filterDatType = (tableData[dataset] && tableData[dataset][table]) ? tableData[dataset][table]?.filter((val)=>val.columnName === col) : "";
          if (filterDatType && filterDatType.length) {
            ddFilterValues.push({ value: filterDatType[0].data_type, label: labelData })
          }

          if (checked[dataset][table][col]) {
            ddOption.push({ value: valueData, label: labelData });
            groupByValue.push(labelData);
          }

        });
      });
    });


    setDDSortValue((prevState) => [...ddsortOption])
    setDDFiltervalues((prevState) => [...ddFilterValues])
    dispatch({ type: "DDValue", payload: ddOption });
    dispatch({ type: "GroupByValue", payload: groupByValue });
  // }

  };
  
  // Need to Modify the code
  const getCheckedColumns = () => {
    const checkedColumns = {};

    Object.keys(checked)?.map((dataset) => {
      checkedColumns[dataset] = {}; // initialize the object for dataset

      Object.keys(checked[dataset])?.map((table) => {
        checkedColumns[dataset][table] = {}; // initialize the object for table
        checkedColumns[dataset][table].columns = [];

        Object.keys(checked[dataset][table])?.map((col) => {
          if (checked[dataset][table][col]) {
            checkedColumns[dataset][table].columns.push(col);
          }
        });
      });
    });

    setSelectedColumn(checkedColumns);
  };

  // Onchange for Sort values
  const handleSortChange = (selected, action) => {
    
        if(selected.length===0){
        dispatch({ type: "SortKeyValue", payload: {} });
    
        }
    dispatch({ type: "SortValue", payload: selected });

    // IF New Data Added.
    if (action.action === "select-option") {

      const sortArray= sortKeyvalue;

      const sortKey= action.option.value.split(".")[0]
      const sortOrder= action.option.label.split(".")[2]

      
      const sortValue= {
        "col": action.option.label.split(".").slice(0,-1).join("."),
        "sort": sortOrder
      }

      if(!sortArray[sortKey]){
        sortArray[sortKey]=[]
      }

      sortArray[sortKey].push(sortValue);  
      dispatch({ type: "SortKeyValue", payload: sortArray });

    }

    // If a data is Removed
    if (action.action === "remove-value") {

      const removeKey= action.removedValue.value.split(".")[0]
      const filterArray= sortKeyvalue[removeKey].filter((val)=>val.col !== action.removedValue.label);
      
      const newSortObj =sortKeyvalue
      newSortObj[removeKey]=filterArray
      
      dispatch({ type: "SortKeyValue", payload: newSortObj });

    }


  };

  const formatOptionLabel = ({ value, label, sort }) => {

    const labelValue = label.split(".")[0].concat(".", label.split(".")[1])
    return(
    <div style={{ display: "flex" }}>
      <div>{labelValue}</div>
      <div style={{ marginLeft: "10px", fontWeight:"bold"}} >
        {label.split(".")[2]}

      </div>
    </div>)
  };

  // Button active on not
  const handleButtonClick = (buttonName) => {
    dispatch({ type: "ActiveTab", payload:buttonName });
  };

  //Onchange for Query for Future purpose
  const handleQueryChange = (event) =>{
    dispatch({ type: "UpdateQuery", payload:event.target.value });
  }

  // Preview Component
  const PreviewComponent=()=>{
    return (<Row>
    <div className="h4">Result</div>

{Object.keys(previewData).length !== 0 ?
  <div>
    {Object.keys(previewData).map((dataset)=>{
      return(
        <>
            {previewData[dataset]?.map((tablearr)=>{
              if(tablearr["result"].length !==0){
      const title =Object.keys(tablearr["result"][0])[0].split(".")[0];
      const key= Object.keys(tablearr["result"][0])
      const column= []
      
      key?.map((k)=>{
        column.push({Header:k.split(".")[1],     
              Cell: ({ row }) => {
          return (
              <div>{row?.original?.[k]}</div>
          );
      }})
      })

      const value= tablearr["result"]
        return(
      <div>
        <div className="h5">{title}</div>
        <UserTable row={value} columns={column} preview="preview"/>
      </div>)
              }
              
    })}
    {/* </>:<div> No Records Found</div> } */}
        </>
      )
    })}

</div> :<div> No Records Found </div>}
    

  </Row>)
  }


  const handleRemoveFilter = (sequence, index) => {
    if(index !== 0){
    const updatedFilters = filterValues.filter(filter => filter.sequence !== sequence);
    if(updatedFilters.length == index){
      updatedFilters[updatedFilters.length-1]["condition"]=''
    }

    setFilters(updatedFilters); 
    dispatch({ type: "Filters", payload: updatedFilters });
  }else{

    const updatedFilt=[{ sequence: 0, colName: '', Operator: '', Value: '', condition: '' }]
    setFilters(updatedFilt);
    dispatch({ type: "Filters", payload: updatedFilt });

  }
    
  };

  const handleSelect1Change = (data, sequence) => {

    const updatedFilters = filterValues.map(filter => {

      if (filter.sequence === sequence) {
        if(data!=null){
        return { ...filter, colName: {label: data.label, value: data.label}, dataType: data.value, value:''};
        }else{
        return { ...filter, colName: '', dataType: '', value:''};
        }
      }
      return filter;
    });
    setFilters(updatedFilters);
    dispatch({ type: "Filters", payload: updatedFilters });

  };

  const handleSelect2Change = (value, sequence) => {
    const updatedFilters = filterValues.map(filter => {
      if (filter.sequence === sequence) {
        return { ...filter, Operator: value };
      }
      return filter;
    });
    setFilters(updatedFilters);
    dispatch({ type: "Filters", payload: updatedFilters });

  };

  const handleTextChange = (event, sequence) => {
    const updatedFilters = filterValues.map(filter => {
      if (filter.sequence === sequence) {

        return { ...filter, Value: event.target.value };
        // }
      }
      return filter;
    });
    setFilters(updatedFilters);
    dispatch({ type: "Filters", payload: updatedFilters });

  };
  const handleConditionChange = (operator, sequence) => {
    const updatedFilters = filterValues.map(filter => {
      if (filter.sequence === sequence) {
        return { ...filter, condition:operator };
      }

      return filter;
    });

    const nextSequence = updatedFilters.length > 0 ? updatedFilters[filterValues.length - 1].sequence + 1 : 1;
    const updatedFilt=[...updatedFilters, { sequence: nextSequence, colName: '', Operator: '', Value: '', condition: '' }]
    setFilters(updatedFilt);


    dispatch({ type: "Filters", payload: updatedFilt });

  };

  const handleToggleCondition = (operator, sequence) =>{
    const updatedFilters = filterValues.map(filter => {
      if (filter.sequence === sequence) {
        return { ...filter, condition:operator };
      }

      return filter;
    });

    setFilters(updatedFilters);


    dispatch({ type: "Filters", payload: updatedFilters });

  }


  // Case for Data Type 
  const getInputType = (datatype) => {
    switch (datatype) {
      case 'character varying':
        return 'text';
      case 'integer':
        return 'number';
      case 'date':
        return 'date';
      // Add more cases for other datatypes as needed
      default:
        return 'text';
    }
  };

  // OnChange for GroupBY values

  // const handleGroupByChange = (selected)=>{

  //   dispatch({ type: "GroupByValue", payload: selected });

  // }

  // const handleRadioSortChange = (event, val) => {

  //   console.log(val)
  //   const id= val.split(".")[0];
  //   const colName= val.split(".")[1];
  //   const sortValue = !event.target.checked ? "dsc" : "asc";
  //   const newObj = { ...sortKeyvalue };
  //   const sortOptions = newObj[id];
  //   const index = sortOptions.findIndex((option) => option.col === colName);
  //   sortOptions[index].sort = sortValue;
  //   newObj[id] = sortOptions;

  //   // Dispatch for SortKeyValue  used in construction of payload for preview
  //   dispatch({ type: "SortKeyValue", payload: newObj });

  //   const sortArray= [...sortValues]
  //   const sortIndex= sortArray.findIndex((option)=>option.label === colName)
  //   sortArray[sortIndex]["sort"]= sortValue

  //   // Dispatch for local state change
  //   dispatch({ type: "SortValue", payload: sortArray });

  // };

  // Out for Different Logic formatOptionLabel

  // const formatOptionLabel = ({ value, label, sort }) => (
  //   <div style={{ display: "flex" }}>
  //     <div>{label}</div>
  //     <div style={{ marginLeft: "10px", color: "#ccc" }}>
  //       {sort} 
  //       <Switch
  //               checked={sort === "asc"}
  //               onChange={(e) => handleRadioSortChange(e, value)}
  //             />
  //     </div>
  //   </div>
  // );
  
  
  return (
    <>
      {isLoadingDataset ? (
        <div>
     {previewData?.length!==0 || Object.keys(errorQuery).length !==0 ?   
          <div className="d-flex justify-content-end">
    {Object.keys(errorQuery).length ===0 ?<>  <button
        className={`preview ${activeButton === 'Visual' ? 'active' : ''}`}
        onClick={() => handleButtonClick('Visual')}
      >
        Visual
      </button>
      <button
        className={`preview ${activeButton === 'Editor' ? 'active' : ''}`}
        onClick={() => handleButtonClick('Editor')}
      >
        Editor
      </button>
      <button
        className={`preview ${activeButton === 'Preview' ? 'active' : ''}`}
        onClick={() => handleButtonClick('Preview')}
      >
        Preview
      </button> 
      </> :
      <>
      
      <button
        className={`preview ${activeButton === 'Visual' ? 'active' : ''}`}
        onClick={() => handleButtonClick('Visual')}
      >
        Visual
      </button>
      
      <button
        className={`preview ${activeButton === 'ErrorQuery' ? 'active' : ''}`}
        onClick={() => handleButtonClick('ErrorQuery')}
      >
        ErrorQuery
      </button></> }

    </div>:null}


  { activeButton === "Visual"?  
        <div>
          <Row>
              <div
                className="border-bottom" onDrop={handleDrop}
                onDragOver={handleDragOver}
                style={{ minHeight: "40vh", overflowY: "auto", maxHeight:"40vh" }}
              >
                <Container>
                  <Row style={{ display: "flex" }}>
                    {Object.keys(tableData).map((dataset) => {
                      return (
                        <>
                          {Object.keys(tableData[dataset]).map((table) => {
                            return (
                              <>
                                {
                                  <Col
                                    md={5}
                                    xl={5}
                                    style={{
                                      border: "1px solid #ccc",
                                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                      padding: "10px",
                                      margin: "15px",
                                    }}
                                  >
                                    <div className="h5 d-flex justify-content-between">
                                    <div>{table}</div>  
                            <input
                            className=""
                              type="button"
                              name="removeButton"
                              value="🅧"
                              style={{ border: "none" }}
                              onClick={() => {
                                handleRemove(dataset, table);
                              }}
                            />
                                    </div>
                                    <hr style={{margin:"3px"}}/>
                                    {tableData[dataset][table]?.map((col) => {
                                      const isChecked =
                                        checked[dataset]?.[table]?.[
                                          col.columnName
                                        ];
                                      return (
                                        <div className= "d-flex" key={col.columnName}>
                                          <input
                                            type="checkbox"
                                            checked={isChecked}
                                            disabled={isLoadingTableData || isLoadingPreview}
                                            className="mx-3"
                                            onChange={() =>
                                              handleCheck(
                                                table,
                                                col.columnName,
                                                dataset
                                              )
                                            }
                                          />
                                         <div className="text-truncate" title={col.columnName}>
                                          {col.columnName}
                                         </div>
                                        </div>
                                      );
                                    })}
                                  </Col>
                                }
                              </>
                            );
                          })}{" "}
                        </>
                      );
                    })}
                  </Row>
                </Container>
              </div>
         
          </Row>

          {isLoadingTableData && (
            <Spinner
              className="position-absolute opacity-100 top-50 start-50"
              style={{ zIndex: 11 }}
            ></Spinner>
          )}
            {isLoadingPreview && (
            <Spinner

              className="position-absolute opacity-100 top-50 start-50"
              style={{ zIndex: 11 }}
            ></Spinner>
          )}

          {Object.keys(tableData)?.length !== 0 ? (
            <Row className="my-3">
              <Row className="py-2">
                <Col xl={2} className="h5"> Sort </Col>
                <Col xl={7} style={{zIndex:50}}  >
                  <Select
                  // id="sortValues"
                    isClearable
                    isMulti
                    options={ddSortValue}
                    value={sortValues}
                    onChange={handleSortChange} 
                    formatOptionLabel={formatOptionLabel}
                    styles={customStyles}
                                     
                  />
                </Col>
                {/* {
                  Object.keys(sortKeyvalue).length !=0 ? 
                  <Col xl={5}  style={{  height:"100px", overflowY:"auto"}}>
                  <table className="border border-2" style={{ borderCollapse: "collapse", width: "100%", backgroundColor:"white"}}>
   
                    <tbody>
                      {Object.entries(sortKeyvalue).map(([id, columns]) =>
                        columns.map(({ col, sort }) => (
                          <tr key={`${id}-${col}`}>
                            <td
                              style={{ padding: "10px"}}
                            >
                              {col}
                            </td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "10px",
                              }}
                            >
                              <label>
                                Asc
                              </label>
                              <Switch
                checked={sort === "asc"}
                onChange={(e) => handleRadioSortChange(e, id, col)}
              />

                              <label>
                                Desc
                              </label>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </Col>: null
                } */}

              </Row>
              
          <div xl={2} className="h5">Filter { showFilter ?
          <IoMdRemove size={22} onClick={()=>
          setShowFilter(false)
          }/> : <IoMdAdd size={22} onClick={()=>setShowFilter(true)}/>}</div>
          
          {showFilter && filterValues.map((filter, index) => (
            <>
        <Row
          key={filter.sequence}
          className="py-2"
        >
          <Col xl={4} style={{zIndex: 30-index  }}>
            <Select
              className="filterVal"
              isClearable
              options={ddFilterValues} 
              value={filter.colName}
              onChange={(value) => handleSelect1Change(value, filter.sequence)}
              maxMenuHeight={150}
              // style={{zIndex: 30+index   }}

            />
          </Col>
          <Col xl={2}>
            <Select
            options={OperatorOptions}
            isClearable
              value={filter.Operator}
              maxMenuHeight={150}
              onChange={(value) => handleSelect2Change(value, filter.sequence)}
            />
          </Col>
          <Col xl={3}>
            <input
              type={getInputType(filter.dataType)}
              className="filterText"
              value={filter.Value}
              onChange={(event) => handleTextChange(event, filter.sequence)}
            />
          </Col>
            <Col xl={3} className="d-flex">
              <Col>
              <button 
              className="btn btn-light border border-1"
                  // disabled={filters.length === 1}
                  style={{borderRadius:"20px"}}
                  onClick={() => handleRemoveFilter(filter.sequence,index)}>
              <IoMdRemove
                />
              </button>
              
              </Col>
            {       
              filter.condition === "" ?<> 
            <Col>
                <button
                  onClick={() => {handleConditionChange('AND', filter.sequence);
                  }}
                  className={`btn btn-light border border-1 ${filter.condition} === 'AND' ? 'active' : ''`}
                  disabled={filterValues.length-1 !== index}
                >
                  AND
                </button>
              </Col>
              <Col>
                <button
                  onClick={() => {handleConditionChange('OR', filter.sequence)}}
                  disabled={filterValues.length-1 !== index}
                  className={`btn btn-light border border-1 ${filter.condition} === 'OR' ? 'active' : ''`}
                >
                  OR
                </button>
              </Col> 
              </>:
              filter.condition === "OR"?            
              <Col>
              <button
                onClick={() => {handleToggleCondition('AND', filter.sequence);
                }}
                className={`btn btn-light border border-1 ${filter.condition} === 'AND' ? 'active' : ''`}
              >
                AND
              </button>
            </Col> :
                  <Col>
                  <button
                    onClick={() => {handleToggleCondition('OR', filter.sequence)}}
                    className={`btn btn-light border border-1 ${filter.condition} === 'OR' ? 'active' : ''`}
                  >
                    OR
                  </button>
                </Col> 

          }
              

            </Col>
        </Row>
        {
          filterValues.length !== 1 &&
          <Row>
          <div>{filter.condition}</div>
        </Row> }
        </>
      ))}
            </Row>
          ) : (
            <></>
          )}


{/* {previewData.length!==0 ?
          <PreviewComponent/>
          :<div></div>} */}

    </div> :<></>}


    {activeButton === 'Editor' ?
    <div style={{backgroundColor:"black"}}>
      {
        Object.keys(previewData).map((dataset)=>{
          if(previewData[dataset].length !==0){
          return(
            <>
                  {previewData[dataset]?.map((pre)=>{
        return (
          <div className="textArea">{pre.sql}s
 
      </div>)
      })}
            </>
          )
          }else{
            return<div className="bg-light fs-5">No Records Found </div>
          }
        })
      }

   </div>:<></>}



    {activeButton ==="Preview" ?
    <PreviewComponent/>: <></>}
        {activeButton === 'ErrorQuery' ?
    <div style={{backgroundColor:"black"}}>
      {
        Object.keys(errorQuery).map((dataset)=>{
          return(
            <>
                  {/* {errorQuery[dataset]?.map((pre)=>{ */}
        <div className="textArea"><span> {errorQuery[dataset].split("-")[0]}</span> -
        <span className="text-danger"> {errorQuery[dataset].split("-")[1]}</span> </div>
        
    
      {/* })} */}
            </>
          )
        })
      }

    </div>:<></>}
        </div>
      ) : (
        <TableSkeletonLoader
          intialrows={10}
          initalcolumns={1}
        ></TableSkeletonLoader>
      )}
    </>
  );
};

export default TableComponent;
