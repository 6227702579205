import React, { useState, useEffect } from 'react';
import api from '../../../api';
import './styles.scss';
import { Container, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TableSkeletonLoader from '../../loader/table-skeleton-loader/table-skeleton-loader';
import { RouteKeys } from '../../../containers/routes/route-keys';
import { toastr } from 'react-redux-toastr';
import { FaTrashAlt } from 'react-icons/fa';
import { FaEdit } from 'react-icons/fa';
import { Row, Col } from 'react-bootstrap';
import SearchBar from '../../searchBar/searchBar';
import TableModal from '../../modal/tablesModal';
import ModalPage from '../../modal/modal';
import DynamicConfigurationTable from '../configuration-table/table/table';
import Pagination from '../../pagination/pagination';
import Limitdropup from '../../limit-drop-up/limit-drop-up';
import NotFound from '../../../containers/notfound';
import { useSelector } from 'react-redux';
// import MainTable from '../../configuration-table/mainTable/mainTable';
// import { findSecretAndDialect } from '../../../helper/findSecretAndDialect';

export default function Dataset() {
    const navigate = useNavigate();
    const authState = useSelector((state) => state.authUser)
    const [datasource, setDataSource] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSpinner, setIsSpinner] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [limit, setLimit] = useState(10);
    const [tableList, setTableList] = useState([]);
    const [tableModalShow, setTableModalShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [search, setSearch] = useState('');
    const [deleteSuccessId, setDeleteSuccessId] = useState('');


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handlingLimit = (limit) => {
        setLimit(limit);
        setCurrentPage(1);
    };

    // for Search
    const handleSearch = (value) => {
        // setEmptyFilter(!emptyFilter);
        setCurrentPage(1);
        // setFilter({
        //     dataSource: [],
        //     checktypes: [],
        // });
        setSearch(value);
    };

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            view more
        </Tooltip>
    );

    const handleDelete = async (id) => {
        try {
            setIsSpinner(true)
            let res = await api.dataset.deleteDataset({
                resourse: 'api/datasets/delete',
                id: id
            });
            if (res.status !== 200) {
                setIsSpinner(false);
                return toastr.error('Error', res?.message);
            }
            // setDataSource(datasource?.filter((item) => item.id !== id));
            setIsSpinner(false);
            toastr.success('Success', 'Dataset removed');
            setDeleteSuccessId(id);
        } catch (error) {
            toastr.error('Error', error.message);
        }
    };

    const columns = [
        {
            Header: 'Dataset Name',
            Cell: ({ row }) => {
                return (
                    <div>{row?.original?.DatasetName}</div>
                );
            },
        },
        {
            Header: 'Data Source',
            Cell: ({ row }) => {
                return (
                    <div>{row?.original?.Datasource_Name}</div>
                );
            },
        },
        {
            Header: 'Tables',
            Cell: ({ row }) => (
                <>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >

                        <div
                            style={{
                                "white-space": "nowrap",
                                "width": "100px",
                                "overflow": "hidden",
                                "text-overflow": "ellipsis",
                                "cursor": "pointer"
                            }}
                            onClick={() => {
                                setTableModalShow(true);
                                setTableList((row?.original?.setentities).map(tab => tab.entityName));
                            }}
                        >
                            {row?.original?.setentities.map(tab => tab.entityName).toString()}
                        </div>
                    </OverlayTrigger>
                </>
                // <div style={{"max-height": "5em", "overflow": "auto" }}>
                //     {JSON.parse(row?.original?.data || "[]").map((col) => {
                //         return (
                //                 <div
                //                     className="rounded-1 my-1 px-2 py-1"
                //                     style={{ backgroundColor: 'rgb(228 228 228)' }}
                //                 >
                //                     {col.tableName}
                //                 </div>
                //         );
                //     })}
                // </div>
            ),
        },
        {
            Header: 'Status',
            Cell: ({ row }) => (
                <div>{row?.original?.isActive ? "Active" : "InActive"}</div>
            ),
        },
        // {
        //     Header: 'Actions',
        //     Cell: ({ row }) => (
        //         <>
        //             <FaEdit
        //                 className="text-dark mx-2 font-size"
        //                 onClick={() => {
        //                     navigate(`/editDataset/${row.original.id}`);
        //                 }}
        //             ></FaEdit>
        //             <FaTrashAlt
        //                 type="button"
        //                 className="text-dark mx-2 font-size"
        //                 // onClick={() => handleDelete(row.original.id)}
        //                 onClick={() => {
        //                     setModalShow(true)
        //                     setDeleteId(row.original.id)
        //                 }}
        //             ></FaTrashAlt>
        //         </>
        //     ),
        // }
    ];

    const buttonColumns = [{
        Header: 'Actions',
        Cell: ({ row }) =>
            authState?.profile?.role_id === 1 && (
                <>
                    <FaEdit
                        className="text-dark mx-2 font-size"
                        style={{ "cursor": "pointer" }}
                        onClick={() => {
                            navigate(`/editDataset/${row.original.id}`);
                        }}
                    ></FaEdit>
                    <FaTrashAlt
                        type="button"
                        className="text-dark mx-2 font-size"
                        style={{ "cursor": "pointer" }}
                        // onClick={() => handleDelete(row.original.id)}
                        onClick={() => {
                            setModalShow(true)
                            setDeleteId(row.original.id)
                        }}
                    ></FaTrashAlt>
                </>
            ),
    }]

    const fetchDataSourceTable = async () => {
        try {
            setIsLoading(true);
            const request = {
                resource: 'api/datasets',
                limit,
                currentPage,
                search
            };
            const datasourceApi = await api.dataset.getDataset(request);
            if (datasourceApi?.data?.status !== 200) {
                setIsLoading(false);
                console.error(datasourceApi?.data?.message);
                return toastr.error("Error", "Dataset not found");
            }
            setDataSource(datasourceApi?.data?.data?.rows);
            setCurrentPage(datasourceApi?.data?.data?.currentPage)
            setPages(datasourceApi?.data?.data?.totalPages);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toastr.error('Error', 'Vault Data is not found');
        }
    };

    useEffect(() => {
        fetchDataSourceTable();
    }, [limit, currentPage, search, deleteSuccessId]); //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <TableModal
                show={tableModalShow}
                onHide={() => setTableModalShow(false)}
                heading={'Tables'}
                body={tableList}
                close={'Close'}
            />
            <ModalPage
                show={modalShow}
                onHide={() => setModalShow(false)}
                heading={'Delete'}
                body={'Are you sure you want to delete this dataset?'}
                success={'Delete'}
                failure={'Cancel'}
                id={deleteId}
                action={handleDelete}
            />
            <Container >
                <div className="d-flex justify-content-between">
                    {isSpinner && (
                        <Spinner
                            className="position-absolute opacity-100 top-50 start-50"
                            style={{ zIndex: 1 }}
                        ></Spinner>
                    )}
                    <h5 className="text-dark pt-4 fw-bold">Dataset</h5>
                    {
                        authState?.profile?.role_id === 1 &&
                        <div className="d-flex gap-2 mt-3 mb-3">
                            <button
                                type="button"
                                className="btn btn text-light custom_button d-flex flex-row"
                                style={{
                                    backgroundColor: 'rgb(73, 151, 73)',
                                }}
                                onClick={() => navigate(RouteKeys.AddDataset)}
                            >
                                <div className="add-user">
                                    <div className="icons">
                                        <span>Add Dataset</span>
                                    </div>
                                </div>
                            </button>
                        </div>
                    }
                </div>
                <Row>
                    <Col md={4} sm={12} xs={12}>
                        {
                            datasource?.length > 0 &&
                            <SearchBar
                                handleSearch={handleSearch}
                            // emptySearchbar={emptySearchbar}
                            ></SearchBar>
                        }
                    </Col>
                </Row>
                {isLoading ? (
                    <TableSkeletonLoader
                        intialrows={2}
                        initalcolumns={6}
                    ></TableSkeletonLoader>
                ) : (
                    <>
                        {
                            datasource?.length > 0 ?
                                <>
                                    <div
                                        className='my-2'
                                        style={{ height: "500px", backgroundColor: "white", overflowY: "auto" }}
                                    >
                                        <DynamicConfigurationTable
                                            row={
                                                datasource?.length
                                                    ? datasource
                                                    : []
                                            }
                                            columns={columns}
                                            buttonColumns={buttonColumns}
                                            isPagination={false}
                                            isLimit={false}
                                            pages={pages}
                                            limit={limit}
                                        ></DynamicConfigurationTable>
                                    </div>
                                     
                                    <div className="d-flex justify-content-end   w-100 " >
                                        <Row>
                                            <div className="d-flex  mx-3">
                                                <div className="py-2 font-weight-semibold">
                                                    Records per page
                                                </div>
                                                <div className=" px-1">
                                                    <Limitdropup
                                                        limit={limit}
                                                        handlingLimit={handlingLimit}
                                                    ></Limitdropup>
                                                </div>
                                            </div>
                                        </Row>

                                        <div className="py-1">
                                            <Pagination
                                                currentPage={currentPage}
                                                handlePageChange={handlePageChange}
                                                pages={pages}
                                            ></Pagination>
                                        </div>
                                    </div>
                                   
                                </> : <NotFound message="No dataset found" />
                        }
                    </>
                )}
            </Container>
        </>
    );
}
