import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";

import ListTable from "../ListTable/ListTable";
import TableComponent from "../TableComponent/TableComponent";
import QueryBuilderFooter from "../Footer/queryBuilderFooter";

import { toastr } from 'react-redux-toastr';
import api from "../../../api";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { useNavigate, useParams } from 'react-router-dom';


import "../queryBuilder.scss";

let rows;
const EditQuery = () => {

  const [checked, setChecked] = useState({});
  const [textInput, setTextInput] = useState({});
  const [dragContent, setDragContent] = useState(null);
  const [datasets, setDatasets] = useState();
  const [tableDetails, setTableDetails] = useState({})
  let updatedTableData = {}

  const { tableData, sortValues, sortKeyvalue, queryName, editResponse } = useSelector((state) => state.queryBuilder)
  const dispatch = useDispatch();
  const params = useParams();


  useEffect(async () => {


    try {
      dispatch({ type: "ResetState" });
      dispatch({ type: "IsEditing", payload: true });


      const request = {
        resource: 'api/datasets'
      }
      dispatch({ type: "DatasetLoadingResponse", payload: false })


      const datasetResponse = await api.dataset.getDataset(request);

      if (datasetResponse) {
        setDatasets(datasetResponse.data.data.rows);
        rows=datasetResponse.data.data.rows;
        dispatch({ type: "FilteredDatasetResponse", payload: datasetResponse.data.data.rows })
        dispatch({ type: "DatasetLoadingResponse", payload: true })

        getTableResponseMap(datasetResponse.data.data.rows);


      } else {
        setDatasets([]);
        dispatch({ type: "FilteredDatasetResponse", payload: [] })
        dispatch({ type: "DatasetLoadingResponse", payload: true })
      }
    } catch {
      dispatch({ type: "DatasetLoadingResponse", payload: true })
    }


  }, [])

  // UseEffect to get Preview Data
  // useEffect(async () => {
  //   const request = {
  //     resource: 'api/queries/view',
  //     id: params.id,
  //   };

  //   // dispatch({ type: 'ActiveTab', payload: 'Preview' });
  //   dispatch({ type: 'PreviewLoading', payload: true });
  //   const res = await api.dataset.getDatasetById(request);

  //   if (res.data.status === 200) {
  //     dispatch({
  //       type: 'PreviewData',
  //       payload: res.data.data.queryResult,
  //     });
  //     dispatch({ type: 'PreviewLoading', payload: false });
  //     // dispatch({ type: 'ActiveTab', payload: 'Preview' });
  //     dispatch({ type: 'QueryName', payload: res.data.data.queryName });
  //     // dispatch({type:'TableDataLoadingResponse',payload:false}) 


  //     return toastr.success(res.data.message);
  //   } else {
  //     dispatch({ type: 'PreviewLoading', payload: true });
  //     return toastr.error('Error while getting preview');
  //   }
  // }, []);


  const getLabelByValue = (value) => {
    switch (value) {
      case "iseql":
        return "IsEqual";
      case "isneql":
        return "IsNotEqual";
      case "like":
        return "Like";
      case "startwith":
        return "StartsWith";
      case "endwith":
        return "EndsWith";
      case "isnull":
        return "IsNull";
      case "isnotnull":
        return "IsNotNull";
      case "ltn":
        return "IsLesserThan";
      case "ltneq":
        return "IsLesserThan or Equals";
      case "gtn":
        return "IsGreaterThan";
      case "gtneq":
        return "IsGreaterThan or Equals";
      default:
        return "";
    }
  }


  const getTableResponseMap = async (datasetRes) => {
    const request = {
      resource: 'api/queries/find',
      id: params.id,
    };

    const res = await api.dataset.getDatasetById(request);
    const responseData = res.data.data;
    dispatch({ type: 'QueryName', payload: responseData.queryName });
    const querystoreResponse = responseData.query.querystore


    // Get Table Details using API

    let entities = editResponse;
    await Promise.all(Object.keys(querystoreResponse)?.map((queryDatasource) => {

      // EditResponse   
      entities = entities.concat(querystoreResponse[queryDatasource]["entites"])


      dispatch({ type: "EditResponse", payload: entities });
      querystoreResponse[queryDatasource]["DatasetEntity"].map((DSEntity) => {


        const datasetId = DSEntity[0];
        const tableName = DSEntity[1];

        const matchingDataset = datasetRes?.filter((dataset) => dataset.id === datasetId)
        const datasetName = matchingDataset[0].DatasetName
        tableApiFunction(datasetId, datasetName, tableName, queryDatasource)

      })
    }))

    const queryData = responseData.query

    const filterVal = [];
    queryData.filter?.map((fv, index) => {

      if (fv.col != "") {
        const obj = {}
        const splitArr = fv.col.split(".");
        const colVal = splitArr.slice(1).join(".");

        obj["colName"] = { label: colVal, value: colVal }
        obj["Operator"] = { label: getLabelByValue(fv.op), value: fv.op }
        obj["Value"] = fv.val
        obj["sequence"] = fv.sequence;
        obj["condition"] = fv.condition

        filterVal.push(obj);

        if (queryData.filter.length - 1 === index && fv.condition !== "") {

          const updatedFilt = { sequence: index + 1, colName: '', Operator: '', Value: '', condition: '' }
          filterVal.push(updatedFilt);

        }

      }
    })



    dispatch({ type: "Filters", payload: filterVal });

    //  dispatch({ type: "Filters", payload: filterVal });
    dispatch({ type: "SortValue", payload: queryData.sort });



  }

  // Drag Start Component
  const handleDragStart = (event) => {
    setDragContent(event.target);
  };



  // Drop Component
  const handleDrop = async (event) => {
    event.preventDefault();
    if (dragContent && dragContent.tagName === "DIV") {

      const datasetId = dragContent.dataset.datasetId
      const datasetName = dragContent.dataset.datasetName
      const datasetTableName = dragContent.dataset.tableName
      const tableName = datasetTableName
      const dataSourceName = dragContent.dataset.tableId

      dispatch({ type: 'PreviewData', payload: [] })
      dispatch({ type: "ErrorQuery", payload: {} });

      dispatch({ type: "IsEditing", payload: false });
      tableApiFunction(datasetId, datasetName, tableName, dataSourceName)
    }

  };

  const sleep = (ms) => {

    return new Promise((resolve) => {

      setTimeout(resolve, ms);

    });

  };
  const tableApiFunction = async (datasetId, datasetName, tableName, dataSourceName) => {
    const request = {
      resource: 'api/datasets/fields'
    }
    const filterSetEntities = rows?.filter(row => row.id === datasetId)[0]?.setentities;
    const tableId = filterSetEntities?.filter(entites => entites.entityName === tableName)[0].id
    const TableData = {
      "DatasetName": datasetName,
      "Datasource_Name": dataSourceName,
      "table_name": tableName,
      "tableId": parseInt(tableId),
      "type": "table"
    }

    let TableResponse = []

    if (!textInput[datasetId]?.includes(tableName)) {

      dispatch({ type: 'TableDataLoadingResponse', payload: true })

      TableResponse = await api.dataset.getDatasourceTables(request, TableData)

      if (TableResponse) {

        dispatch({ type: 'TableDataLoadingResponse', payload: false })
        setTableDetails(prevTableData => {
          updatedTableData = {
            ...prevTableData,
            [datasetId]: {
              ...(prevTableData?.[datasetId] || {}),
              [tableName]: [
                ...(prevTableData?.[datasetId]?.[tableName] || []),
                ...TableResponse.data.data,
              ],
            },
          };

          return updatedTableData;
        });



        dispatch({ type: 'add table data', payload: updatedTableData })
        setTextInput(prevState => ({
          ...prevState,
          [datasetId]: [
            ...(prevState?.[datasetId] || []),
            tableName
          ]
        }));

      } else {
        dispatch({ type: 'TableDataLoadingResponse', payload: false })
      }

    } else {
      toastr.error('error', 'Table already added');
    }
    setDragContent(null);
    await sleep(300)


    // return handleTableResponse(TableResponse.data.data,datasetId, tableName);

  }

  const handleTableResponse = (tableResponseData, datasetId, tableName) => {

    return {
      ...tableData,
      [datasetId]: {
        ...(tableData?.[datasetId] || {}),
        [tableName]: [...(tableData?.[datasetId]?.[tableName] || []),
        ...tableResponseData,
        ],
      },
    };
  };


  // Drag over component
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Remove table Name From list from selected table name
  const handleRemove = (id, tableName) => {

    // Make a copy of the textInput state using the spread operator
    const newTextInput = { ...textInput };

    // Perform the action using the temp variable
    newTextInput[id] = (newTextInput[id] || []).filter(name => name !== tableName);

    // Update the state with the modified copy
    setTextInput(newTextInput);


    handleSortKeyValueRemove(id, tableName)

    // SetChecked also handled in handleDataSetRemove function

    dispatch({ type: 'add table data', payload: handleDataSetRemove(id, tableName) })
    dispatch({ type: 'SortValue', payload: handleSortValuesRemove(id, tableName) })
    dispatch({ type: 'SortKeyValue', payload: handleSortKeyValueRemove(id, tableName) })
    dispatch({ type: "ErrorQuery", payload: {} });
    dispatch({ type: 'PreviewData', payload: [] })
    dispatch({ type: 'ActiveTab', payload: 'Visual' })
    dispatch({ type: "Filters", payload: [] });




    if (newTextInput[id].length == 0) {
      delete newTextInput[id]
    }



  };


  const handleDataSetRemove = (datasetId, tableName) => {
    const updatedTextInput = { ...tableData };

    // Check if datasetId exists in textInput
    if (updatedTextInput[datasetId]) {
      // Check if tableName exists in datasetId
      if (updatedTextInput[datasetId][tableName]) {
        // Delete tableName from datasetId
        delete updatedTextInput[datasetId][tableName];
        delete checked[datasetId][tableName];

        // Check if datasetId has no other tables
        if (Object.keys(updatedTextInput[datasetId]).length === 0) {

          // Remove datasetId if it has no tables
          delete checked[datasetId];
          delete updatedTextInput[datasetId];


        }
      }
    }
    return updatedTextInput;
  };

  const handleSortValuesRemove = (datasetId, tableName) => {

    if (Object.keys(sortValues).length != 0) {

      const value = datasetId.concat(".", tableName)
      const filteredValue = sortValues.filter((sortValue) => !sortValue.value.includes(value));
      return filteredValue;
    } else {
      return [];
    }

  }

  const handleSortKeyValueRemove = (datasetId, tableName) => {

    if (Object.keys(sortKeyvalue).length != 0) {
      const newObj = { ...sortKeyvalue };
      newObj[datasetId] = newObj[datasetId].filter(({ col }) => !col.startsWith(`${tableName}.`));
      if (newObj[datasetId].length === 0) {
        delete newObj[datasetId];
      }

      return newObj;
    } else {
      return [];
    }
  }

  const handleNameChange = (e) => {
    dispatch({ type: 'QueryName', payload: e.target.value })
  }




  return (<>
    <Container style={{ maxWidth: '100%' }}>
      <Row className="my-3">
        <Col xl={6} md={6} sm={6} xxl={6}>
          <input type="text" value={queryName} class="form-control" id="queryName" placeholder="Enter QueryName" onChange={handleNameChange} />
        </Col>
      </Row>
      <Container style={{ maxWidth: '100%' }}>
        <Row >
          <Col xl={3} md={3} sm={3} xxl={3} style={{ zIndex: "10" }}>
            <ListTable
              handleDragStart={handleDragStart}
              handleDrop={handleDrop}
              handleDragOver={handleDragOver}
              textInput={textInput}
              setTextInput={setTextInput}
              datasets={datasets}
              tableApiFunction={tableApiFunction}
              handleRemove={handleRemove}
            />
          </Col>

          <Col xl={9} md={9} sm={9} xxl={9} className="tableContent">
            <TableComponent
              handleDragStart={handleDragStart}
              handleDrop={handleDrop}
              handleDragOver={handleDragOver}
              textInput={textInput}
              setTextInput={setTextInput}
              handleRemove={handleRemove}
              checked={checked}
              setChecked={setChecked}
              datasets={datasets}
            />
          </Col>
        </Row>
        <Row className="">
          <QueryBuilderFooter screen="editQuery" />
        </Row>
      </Container>
    </Container>

  </>);
}

export default EditQuery;